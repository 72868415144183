import React from 'react';
import { useGetCourseEvent } from '../../../BusinessLogic/hooks/query/useQueryCourses';
import { ReactComponent as Logo } from '../../assets/icons/logoDatarteNegro.svg';
import PortfolioLoading from '../Loading/PortfolioLoading';
import { useTranslation } from 'react-i18next';

const CourseEvent = () => {
	const { t } = useTranslation();
	const { courseEvent, isLoading } = useGetCourseEvent();

	if (isLoading) return <PortfolioLoading />;
	if (!courseEvent) return null;

	const { title, description, datetime, teacher } = courseEvent;
	const formattedDate = new Date(datetime).toLocaleDateString('es-ES', {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
	const formattedTime = new Date(datetime).toLocaleTimeString('es-ES', {
		hour: '2-digit',
		minute: '2-digit',
		hour12: true,
	});

	return (
		<div className='mx-auto mb-8 mt-5 bg-purple-50 px-8 py-7 lg:mt-0 lg:px-12 lg:py-9'>
			<h1 className='mb-4 text-xl font-medium text-white'>
				{t('marcaTuCalendario')}
			</h1>

			<h2 className='mb-3 text-3xl font-semibold text-white'>{title}</h2>

			{teacher && (
				<p className='text-lg text-gray-600'>
					<span className='font-bold text-gray-800'>
						{t('dictadoPor')} {teacher.name},
					</span>
					{teacher.description && (
						<span className='text-white'> {teacher.description}</span>
					)}
				</p>
			)}

			<div className='my-2 inline-block space-y-2 text-lg text-gray-800'>
				<p className=' bg-white '>
					{t('fecha')}: {formattedDate}
				</p>
				<p>
					{t('hora')}: {formattedTime} {t('horaBogota')}
				</p>
			</div>

			{description && (
				<p className='mt-4 text-lg font-light text-black-50'>{description}</p>
			)}

			<Logo className='ml-auto mt-4 w-[100px] [&>path]:fill-white' />
		</div>
	);
};

export default CourseEvent;
