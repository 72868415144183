//3rd party
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// BL
import { useFetchHomeData } from '../../BusinessLogic/hooks/fetchDataHooks';
import { useGetAllCallsByRole } from '../../BusinessLogic/hooks/query/useQueryCalls';

//UI
import HomeSkeleton from '../SkeletonLoadingPages/HomeSkeleton';
import TemplateWithNavBar from '../../UI/templates/TemplateWithNavBar';
import SectionArtistHome from '../../UI/organisms/Section/SectionArtistHome';
import SectionOrganizationHome from '../../UI/organisms/Section/SectionOrganizationHome';
import SectionResources from '../../UI/organisms/Section/SectionResources';
import { useEffect, useState } from 'react';
import { ModalPostulation } from '../../UI/organisms/PopUp/ModalPostulation';
import { showActivePlanModalKey } from '../../BusinessLogic/data/ArtistSingleData';
import { getAppUser } from '../../BusinessLogic/redux/reducers/appUser';
import { getAllCalendarEvents } from '../../BusinessLogic/redux/reducers/calendar';
import { getAllResources } from '../../BusinessLogic/redux/reducers/resources';

/**
 * @description manages the Home page.
 * @returns
 */

export default function Homepage() {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { appUser } = useSelector((state) => state.appUser);
	const { calendarEvents } = useSelector((state) => state.calendar);
	const { resources } = useSelector((state) => state.resources);

	const roleCompany = appUser.role_company;
	const activePlan = appUser.subscription_type;
	const isActivePlanFree = activePlan === 'PlanGratis';
	const isGalleryUser = roleCompany === 'GALLERY';
	const isArtistUser = roleCompany === 'ARTIST';
	const isCatalogueArtistUser = roleCompany === 'ARTIST_CATALOGATION';
	const isOrganizationUser = roleCompany === 'ORGANIZATION';
	const limitPicture = 4;

	const [showActivePlanModal, setShowActivePlanModal] = useState(false);

	useEffect(() => {
		dispatch(getAppUser());
		dispatch(getAllResources());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const activePlanModalTitle = !isActivePlanFree
		? t('TuPlanEstaActivo')
		: t('BienvenidoADatarte');
	const activePlanModalText = !isActivePlanFree
		? t('TuPlanSeHaActivadoExitosamente')
		: undefined;

	const { calls } = useGetAllCallsByRole({
		organization_user_id: appUser.organization_user_id,
		role_company: appUser.role_company,
	});

	const isLoading = useFetchHomeData(
		isArtistUser,
		isGalleryUser,
		isCatalogueArtistUser,
		isOrganizationUser
	);

	const filterCallsByStatus = () => {
		if (Array.isArray(calls)) {
			return calls.filter((call) => call.status === 'OPEN');
		} else {
			return [];
		}
	};

	const sortCallsByRecentDate = () => {
		const filteredCalls = filterCallsByStatus();
		return filteredCalls.sort((a, b) => {
			const dateA = new Date(a?.updated_at ?? '');
			const dateB = new Date(b?.updated_at ?? '');
			return dateB - dateA;
		});
	};

	const sortCallsByCloseDate = () => {
		const filteredCalls = filterCallsByStatus();
		return filteredCalls.sort((a, b) => {
			const dateA = new Date(a?.closes_at ?? '');
			const dateB = new Date(b?.closes_at ?? '');
			return dateA - dateB;
		});
	};

	const onActivePlanModalClose = () => {
		setShowActivePlanModal(false);
		localStorage.setItem(showActivePlanModalKey, JSON.stringify(false));
	};

	useEffect(() => {
		if (isArtistUser) {
			const localStorageShowActivePlanModal = JSON.parse(
				localStorage.getItem(showActivePlanModalKey)
			);
			setShowActivePlanModal(localStorageShowActivePlanModal);

			return;
		}

		setShowActivePlanModal(false);

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		dispatch(getAllCalendarEvents());
	}, [dispatch, calls]);

	if (isLoading) {
		return (
			<HomeSkeleton
				numberOfImagesTop={limitPicture}
				numberOfImagesBottom={limitPicture}
			/>
		);
	}

	return (
		<TemplateWithNavBar>
			{showActivePlanModal && (
				<ModalPostulation
					actionClose={onActivePlanModalClose}
					modalTitle={activePlanModalTitle}
					modalText={activePlanModalText}
					buttonLabel={t('Aceptar')}
					testId={'modal-active-plan'}
				/>
			)}
			{(isArtistUser || isCatalogueArtistUser || isGalleryUser) && (
				<SectionArtistHome
					calls={sortCallsByRecentDate().slice(0, 3)}
					calendarEvents={calendarEvents}
				/>
			)}
			{isOrganizationUser && (
				<SectionOrganizationHome calls={sortCallsByCloseDate()} />
			)}
			<SectionResources resources={resources} />
		</TemplateWithNavBar>
	);
}
