import TemplateWithNavBar from '../../templates/TemplateWithNavBar';
import NavbarSecondaryNew from '../Navbar/NavbarSecondaryNew';
import TextTitleSub from '../../atoms/Text/Titles/TextTitleSub';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import CourseEvent from '../Courses/CourseEvent';
import CoursesGrid from '../Courses/CoursesGrid';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const Membership = () => {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<TemplateWithNavBar section='membresia'>
				<NavbarSecondaryNew
					title={t('membresia')}
					color='bg-green-50'
					textColor='text-green-50'
					showDots={false}
					showBackButton={false}
				/>
				<CourseEvent />
				<TextTitleSub
					title={t('cursosVirtuales')}
					design='text-purple-50 w-full !text-2xl  border-purple pb-2 font-bold'
				/>
				<TextContentSecond
					design={'break-words whitespace-pre-line w-full pt-4 !text-lg'}
					text={t('cursosVirtualesDescripcion')}
				/>
				<CoursesGrid seeAll className='mt-8' />
				<section className='mt-20 flex flex-col items-center justify-center pb-32'>
					<TextTitleSub
						title={t('mentoriaVirtual')}
						design='text-purple-50 w-full !text-2xl  border-purple  pb-2 font-bold'
					/>
					<TextContentSecond
						design={'break-words whitespace-pre-line w-full py-4 !text-lg'}
						text={t('mentoriaVirtualDescripcion')}
					/>
					<a
						href='https://us06web.zoom.us/meeting/register/tZ0ucOyrrz8tHd3VZjglpBVMlyapcwulXA0N#/registration'
						className={`mx-auto mt-5 block w-2/3 rounded-3xl bg-green-50 px-5 py-2 text-center text-sm text-white lg:w-1/3 lg:text-base`}
						target='_blank'
						rel='noreferrer'
					>
						{t('registrateAqui')}
					</a>
					<TextTitleSub
						title={t('materialDeApoyo')}
						design='text-purple-50 w-full !text-2xl border-purple mt-8 pb-2 font-bold'
					/>
					<TextContentSecond
						design={
							'break-words whitespace-pre-line text-base w-full py-4 !text-lg'
						}
						text={t('materialDeApoyoDescripcion')}
					/>
					<a
						href='https://drive.google.com/drive/u/0/folders/1NxoFIM7ItPI1Q9ZiFT2yxbtsZ4d_ZL1V'
						className={`mx-auto mt-5 block w-2/3 rounded-3xl bg-purple-50 px-5 py-2 text-center text-sm text-white lg:w-1/3 lg:text-base`}
						target='_blank'
						rel='noreferrer'
					>
						{t('linkMaterialDeApoyo')}
					</a>
				</section>
			</TemplateWithNavBar>
		</>
	);
};

export default Membership;
