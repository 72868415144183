import { CALL_STATUS } from '../data/call';

export const textColorStatus = (status, roleCompany) => {
	const isArtistUser = roleCompany === 'ARTIST';
	const isStatusDraft = status === 'DRAFT';

	const callStatus = CALL_STATUS(roleCompany);
	const statusColor = callStatus[status].textColor;

	if (!isArtistUser) {
		return statusColor || 'text-blue-500';
	}

	return isStatusDraft
		? callStatus['DRAFT'].textColor
		: callStatus['SUBMITTED'].textColor;
};

export const statusToShow = (status, roleCompany) => {
	const isArtistUser = roleCompany === 'ARTIST';
	const isStatusDraft = status === 'DRAFT';

	const callStatus = CALL_STATUS(roleCompany);
	const statusText = callStatus[status].text;

	if (!isArtistUser) {
		return statusText || '';
	}

	return isStatusDraft
		? callStatus['DRAFT'].text
		: callStatus['SUBMITTED'].text;
};
