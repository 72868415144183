import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Formik, Field } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

// BL

import {
	usePostPostulationForm,
	usePostPostulationFormDocument,
	useDeletePostulationFormDocument,
	usePutPostulationForm,
} from '../../../BusinessLogic/hooks/query/useQueryPostulation';

import { navbarOptionsArtist } from '../../../BusinessLogic/data/NavbarOptions';
import { useGetPostulationSingle } from '../../../BusinessLogic/hooks/query/useQueryPostulation';

import {
	useGetCallForm,
	useGetSingleCall,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';

// UI

import ButtonLinkBack from '../../../UI/atoms/Buttons/Link/ButtonLinkBack';
import ButtonLinkBackText from '../../../UI/molecules/Buttons/Link/ButtonLinkBackText';
import TextTitlePrincipal from '../../../UI/atoms/Text/Titles/TextTitlePrincipal';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import TextSubsectionTitle from '../../../UI/molecules/Text/TextSubsectionTitle';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import DropZoneModalDocument from '../../../UI/molecules/DropZone/DropZoneModalDocument';
import { ReactComponent as DeleteCanGrey } from '../../../UI/assets/icons/DeleteCanGrey.svg';
import Cross from '../../../UI/assets/icons/Cross.svg';
import CheckIcon from '../../../UI/assets/icons/whiteCheck.svg';
import SpinnerWhite from '../../../UI/assets/icons/loadingSpinnerSmallWhite.svg';
import ViewModal from '../../../UI/templates/ViewModal';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import FieldTextAreaFormik from '../../../UI/molecules/Field/FieldTextAreaFormik';
import Pencil from '../../../UI/assets/icons/pencil.svg';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';

/**
 * It renders a form for the postulation call, and it sends the data to the backend
 * @returns A component
 */

const PostulationForm = () => {
	const { id: postulationId } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { appUser } = useSelector((state) => state.appUser);

	const navbarOptionsArtistToUse = navbarOptionsArtist(appUser);

	const { postulationStatusDocument } = useSelector(
		(state) => state.postulation
	);
	const { postulationSingle } = useGetPostulationSingle(postulationId);

	const { singleCall } = useGetSingleCall(postulationSingle?.call_id);
	const { singleCallForm } = useGetCallForm(singleCall?.form_id);

	const postPostulationFormMutation = usePostPostulationForm();
	const postPostulationFormDocumentMutation = usePostPostulationFormDocument();
	const deletePostulationFormDocumentMutation =
		useDeletePostulationFormDocument();

	const putPostulationFormMutation = usePutPostulationForm();

	const [showDropZoneModal, setShowDropZoneModal] = useState(false);
	const [dropZoneFiles, setDropZoneFiles] = useState([]);
	const [initialObjectsFromForm, setInitialObjectsFromForm] = useState([]);
	const [filesToUpload, setFilesToUpload] = useState([]);
	const [filesToShowFromDropzone, setFilesToShowFromDropzone] = useState([]);
	const [filesToShowFromApi, setFilesToShowFromApi] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [idOfPostulationFileToDelete, setIdOfPostulationFileToDelete] =
		useState(null);
	const [idOfFormFileToDelete, setIdOfFormFileToDelete] = useState(null);
	const [isFormEditable, setIsFormEditable] = useState(
		postulationSingle?.form_completed === 'Completed' ? false : true
	);
	const [documentUploadFieldIdSelected, setDocumentUploadFieldIdSelected] =
		useState(null);

	const [isMissingRequiredDocument, setIsMissingRequiredDocument] =
		useState(null);
	const [loading, setLoading] = useState(false);

	const callFormElements = singleCallForm?.forms?.form_elements;
	const addDocFormElementsRequiredIds = callFormElements
		?.filter(
			(object) => object.is_mandatory === true && object.type === 'ADD_DOC'
		)
		.map((object) => object.id)
		.sort()
		.join(',');

	const finalDocsFormElementsIds = [...filesToUpload, ...filesToShowFromApi]
		.map((object) => object.form_element_id)
		.sort()
		.join(',');

	const isArtistUser = appUser.role_company === 'ARTIST';
	const isCatalogueArtistUser = appUser.role_company === 'ARTIST_CATALOGATION';
	const isOrganizationUser = appUser.role_company === 'ORGANIZATION';
	const isGalleryUser = appUser.role_company === 'GALLERY';

	useEffect(() => {
		const arrFilesToShow = [];
		const arrayObjectsForm = [];
		if (postulationSingle?.forms) {
			postulationSingle?.forms?.forEach((element) => {
				if (Object.keys(element)[0] === 'document_id') {
					arrFilesToShow.push(element);
				}
				if (Object.keys(element)[0] !== 'document_id') {
					arrayObjectsForm.push(element);
				}
			});
			setFilesToShowFromApi(arrFilesToShow);

			setInitialObjectsFromForm(arrayObjectsForm);
		}
	}, [postulationSingle?.forms]);

	useEffect(() => {
		appUser.role_company !== 'GALLERY' &&
			(navbarOptionsArtistToUse[0].url = `/artistas/${appUser.main_artist_id}`);
	}, [navbarOptionsArtistToUse, appUser]);

	const updateFiles = (incomingFiles) => {
		setDropZoneFiles(incomingFiles);
		incomingFiles[0].form_element_id = documentUploadFieldIdSelected;
		setFilesToUpload([...filesToUpload, incomingFiles[0]]);
		setFilesToShowFromDropzone([...filesToShowFromDropzone, incomingFiles[0]]);
	};

	const handleDeleteDropZone = (id) => {
		setDropZoneFiles(dropZoneFiles.filter((file) => file.id !== id));
		setFilesToUpload(filesToUpload.filter((file) => file.id !== id));
		setFilesToShowFromDropzone(
			filesToShowFromDropzone.filter((file) => file.id !== id)
		);
	};

	const handleDeleteFilesToUpload = (id) => {
		setFilesToUpload(filesToUpload.filter((file) => file.id !== id));

		setFilesToShowFromApi(
			filesToShowFromApi.filter((file) =>
				file.id ? file.id !== id : file.document_id !== id
			)
		);

		setFilesToShowFromDropzone(
			filesToShowFromDropzone.filter((file) =>
				file.id ? file.id !== id : file.document_id !== id
			)
		);
	};

	const handleDeletePostulationFormFile = async (id) => {
		await deletePostulationFormDocumentMutation.mutateAsync(id);
	};

	const handleDeleteDocument = (
		idOfPostulationFileToDelete,
		idOfFormFileToDelete
	) => {
		idOfFormFileToDelete && handleDeleteFilesToUpload(idOfFormFileToDelete);

		idOfPostulationFileToDelete &&
			handleDeletePostulationFormFile(idOfPostulationFileToDelete);
	};

	const clearDropzone = () => {
		setDropZoneFiles([]);
		setShowDropZoneModal(false);
	};

	const renderSwitchFormElementType = (element, errorMessage) => {
		let documentToBeDisplayed =
			filesToShowFromApi.find((obj) => obj.form_element_id === element.id) ||
			filesToShowFromDropzone.find((obj) => obj.form_element_id === element.id);

		switch (element.type) {
			case 'TEXTO_CORTO':
				return (
					<div className='my-8'>
						<TextSubsectionTitle
							classNameContainer={'text-left'}
							classNameHr={'mb-4 w-full'}
							title={element.name}
							color={'#CBBFD9'}
							textColor={'text-[#CBBFD9]'}
						/>
						<Field
							as='input'
							maxLength='150'
							type='text'
							name={element.id}
							className='h-[2rem] w-full bg-[#DEDFE2] disabled:bg-white'
							required={element.is_mandatory ? true : false}
							disabled={!isFormEditable}
						/>
						{errorMessage && (
							<div className={`ml-auto w-full text-right text-sm text-red-400`}>
								{t(errorMessage)}
							</div>
						)}
					</div>
				);
			case 'TEXTO_LARGO':
				return (
					<div className='my-8'>
						<TextSubsectionTitle
							classNameContainer={'text-left '}
							title={element.name}
							color={'#CBBFD9'}
							textColor={'text-[#CBBFD9]'}
						/>
						<FieldTextAreaFormik
							dbName={element.id}
							designInput={'h-[10rem] w-full bg-[#DEDFE2] disabled:bg-white'}
							errors={errorMessage}
							errorMessageDesign={'text-red-400 ml-auto text-sm text-right'}
							isInputDisabled={!isFormEditable}
						/>
					</div>
				);
			case 'ADD_DOC':
				return (
					<div className='my-8'>
						<TextSubsectionTitle
							classNameContainer={'text-left'}
							title={element.name}
							color={'#CBBFD9'}
							textColor={'text-[#CBBFD9]'}
						/>
						{documentToBeDisplayed && (
							<p className='py-1'> Documento adjuntado: </p>
						)}

						{
							<>
								{documentToBeDisplayed && (
									<div className='flex justify-between'>
										<p className='py-4 text-[#34B3E5]'>
											{documentToBeDisplayed.file
												? documentToBeDisplayed.file.name
												: documentToBeDisplayed.form_value}
										</p>
										{isFormEditable && (
											<div className='cursor-pointer py-4'>
												<DeleteCanGrey
													onClick={() => {
														setShowDeleteModal(true);

														documentToBeDisplayed.valid &&
															setIdOfFormFileToDelete(documentToBeDisplayed.id);
														documentToBeDisplayed.document_id &&
															setIdOfPostulationFileToDelete(
																documentToBeDisplayed.document_id
															);
													}}
												/>
											</div>
										)}
									</div>
								)}
							</>
						}
						{isFormEditable && !documentToBeDisplayed && (
							<>
								{
									<div>
										{element.is_mandatory && isMissingRequiredDocument && (
											<div
												className={`ml-auto  w-full text-right text-sm text-red-400`}
											>
												{t('CampoRequerido')}
											</div>
										)}
									</div>
								}
								<div className='flex w-full flex-col items-center'>
									<ButtonFabExtendedPrimary
										colorClass={'bg-white'}
										className={'text-black'}
										text={'Adjuntar'}
										typeButton={'button'}
										action={() => {
											setShowDropZoneModal(true);

											setDocumentUploadFieldIdSelected(element.id);
										}}
									/>
								</div>
							</>
						)}
					</div>
				);

			default:
				return (
					<div className='my-8'>
						<TextSubsectionTitle
							title={element.title}
							color={'#CBBFD9'}
							textColor={'text-[#CBBFD9]'}
						/>
						<Field className='textarea' type='text' name={element.title} />
					</div>
				);
		}
	};

	const renderSwitchFormContentGalleryView = (element) => {
		switch (element.form_type) {
			case 'TEXTO_CORTO':
			case 'TEXTO_LARGO':
				return (
					<div className='my-8 '>
						<TextSubsectionTitle
							classNameContainer={'text-left'}
							classNameHr={'mb-4 w-full'}
							title={`${element.form_element_title}`}
							color={'#CBBFD9'}
							textColor={'text-[#CBBFD9]'}
						/>
						<TextContentSecond text={element.form_value} />
					</div>
				);

			case 'ADD_DOC':
			default:
				return (
					<div className='my-8 '>
						<TextSubsectionTitle
							classNameContainer={'text-left'}
							classNameHr={'mb-4 w-full'}
							title={`${element.form_element_title}`}
							color={'#CBBFD9'}
							textColor={'text-[#CBBFD9]'}
						/>
						<div className='flex flex-row gap-1'>
							<TextContentSecond text={`Documento adjuntado:`} />
							<a
								href={`${element?.form_type}`}
								target='_blank'
								rel='noreferrer'
							>
								<TextContentSecond
									text={`${element.form_value}`}
									design={'text-[#34B3E5] underline'}
								/>
							</a>
						</div>
					</div>
				);
		}
	};

	const initialValuesForm = () => {
		const initialKeysArray = [];

		callFormElements?.forEach((element) => {
			if (element.type !== 'ADD_DOC') {
				initialKeysArray.push(element.id);
			}
		});

		let initialValues = {};
		initialKeysArray.forEach((key) => {
			initialObjectsFromForm?.forEach((value) => {
				if (key === value.form_element_id) {
					initialValues[key] = value.form_value;
				} else if (initialObjectsFromForm.length < 0) {
					initialValues[key] = '';
				}
			});
		});

		return initialValues;
	};

	const postulationFormValidationSchema = (formElements) => {
		const objectValidation = {};
		formElements?.forEach((element) => {
			if (element.type === 'TEXTO_CORTO') {
				if (element.is_mandatory) {
					objectValidation[element.id] = Yup.string()
						.min(2, 'RequiereDosCaracteres')
						.required('CampoRequerido');
				} else {
					objectValidation[element.id] = Yup.string()
						.min(2, 'RequiereDosCaracteres')
						.nullable();
				}
			} else if (element.type === 'TEXTO_LARGO') {
				if (element.is_mandatory) {
					objectValidation[element.id] = Yup.string()
						.max(10000, 'NumeroMáximoCaracteres')
						.required('CampoRequerido');
				} else {
					objectValidation[element.id] = Yup.string()
						.max(10000, 'NumeroMáximoCaracteres')
						.nullable();
				}
			}
		});

		return Yup.object(objectValidation);
	};

	const onSubmitAction = async (values) => {
		let valuesElementsPostulationForm = Object.entries(values).map((entry) => {
			return {
				value: entry[1],
				form_element_id: Number(entry[0]),
				element_id: 0,
			};
		});

		let documentsPostulationForm = [];
		filesToUpload.map((file) => {
			return documentsPostulationForm.push({
				filesArray: file,
				form_element_id: file.form_element_id,
				postulation_id: postulationSingle?.id,
			});
		});

		const valuesPostulation = {
			postulation_id: postulationSingle?.id,
			form_elements: valuesElementsPostulationForm,
		};

		if (postulationSingle?.forms && postulationSingle?.forms?.length < 1) {
			postPostulationFormMutation.mutateAsync(valuesPostulation).then(() => {
				filesToUpload.length < 1 && navigate(-1);
			});

			if (filesToUpload.length > 0) {
				setLoading(true);
				documentsPostulationForm.map((file) => {
					return postPostulationFormDocumentMutation
						.mutateAsync(file)
						.then(() => {
							setLoading(false);

							navigate(`/postulaciones/${postulationSingle?.id}`);
						});
				});
			}
		} else if (postulationSingle?.forms?.length >= 1) {
			putPostulationFormMutation.mutateAsync(valuesPostulation).then(() => {
				filesToUpload.length < 1 && navigate(-1);
			});
			filesToUpload.length > 0 && setLoading(true);
			documentsPostulationForm.map((file) => {
				return postPostulationFormDocumentMutation
					.mutateAsync(file)
					.then(() => {
						setLoading(false);
						navigate(-1);
					});
			});
		}
	};

	if (
		isOrganizationUser ||
		((isGalleryUser || isArtistUser || isCatalogueArtistUser) &&
			postulationSingle?.status !== 'DRAFT')
	) {
		return (
			<div className='container mx-auto px-2 md:px-4 lg:px-8 2xl:px-10'>
				<div className='mt-8 flex md:grid md:grid-cols-3'>
					<ButtonLinkBack
						redirect={`/postulaciones/${postulationSingle?.id}`}
						design='md:hidden'
					/>
					<ButtonLinkBackText
						design='hidden md:block w-full'
						textBack={`${t('RegresarAPostulación')}`}
						textBackDesign='pl-3 text-[#CBBFD9]'
						redirect={`/postulaciones/${postulationSingle?.id}`}
						arrowLeftColor='#CBBFD9'
					/>
					<TextTitlePrincipal
						title={'Formulario'}
						textColor='text-[#CBBFD9]'
						color='bg-[#CBBFD9]'
						design='text-center flex flex-col w-full mr-[10%]'
					/>
				</div>

				<div className='my-8'>
					{postulationSingle?.forms?.map((element, index) => (
						<div key={index}>{renderSwitchFormContentGalleryView(element)}</div>
					))}
				</div>
			</div>
		);
	}

	return (
		<TemplateWithNavBar>
			<div className='mt-8 flex px-2 md:grid md:grid-cols-3'>
				<ButtonLinkBack
					redirect={`/postulaciones/${postulationSingle?.id}`}
					design='md:hidden'
				/>
				<ButtonLinkBackText
					design='hidden md:block w-full'
					textBack={`${t('RegresarAPostulación')}`}
					textBackDesign='pl-3 text-[#CBBFD9]'
					redirect={`/postulaciones/${postulationSingle?.id}`}
					arrowLeftColor='#CBBFD9'
				/>
				<TextTitlePrincipal
					title={'Formulario a diligenciar'}
					textColor='text-[#CBBFD9]'
					color='bg-[#CBBFD9]'
					design='text-center flex flex-col w-full mr-[10%]'
				/>
			</div>
			<div className='my-8'>
				<Formik
					enableReinitialize={true}
					initialValues={initialValuesForm()}
					validationSchema={postulationFormValidationSchema(callFormElements)}
					onSubmit={(values) => {
						const hasAllRequiredDocumentsBeenAdded =
							finalDocsFormElementsIds.includes(addDocFormElementsRequiredIds);
						setIsMissingRequiredDocument(!hasAllRequiredDocumentsBeenAdded);
						hasAllRequiredDocumentsBeenAdded && onSubmitAction(values);
					}}
				>
					{(formProps) => (
						<Form>
							{callFormElements?.map((element, index) => (
								<div key={index}>
									{renderSwitchFormElementType(
										element,
										formProps.errors[element.id]
									)}
								</div>
							))}
							{isFormEditable ? (
								<div className='mb-16 mt-8 flex w-full flex-row justify-center gap-3'>
									<ButtonFab
										design={'drop-shadow-lg shadow-inner text-grey-50'}
										label='Cancelar'
										colour='white'
										action={() => {
											navigate(-1);
										}}
										icon={Cross}
									/>

									<ButtonFab
										disabled={
											loading || postulationStatusDocument === 'loading'
										}
										colour='green'
										label={
											loading || postulationStatusDocument === 'loading'
												? 'Guardando'
												: 'Guardar'
										}
										type='submit'
										icon={
											loading || postulationStatusDocument === 'loading'
												? SpinnerWhite
												: CheckIcon
										}
									/>
								</div>
							) : (
								<div className='mb-16 mt-8 flex w-full flex-row justify-center gap-3'>
									<ButtonFab
										design={'drop-shadow-lg shadow-inner text-grey-50'}
										label={t('Editar')}
										colour='white'
										action={() => setIsFormEditable(true)}
										icon={Pencil}
									/>
									<ButtonFab
										design={'drop-shadow-lg shadow-inner text-grey-50'}
										label={t('Continuar')}
										action={() => navigate(-1)}
									/>
								</div>
							)}
						</Form>
					)}
				</Formik>
			</div>
			{showDeleteModal && (
				<ViewModal
					setConfirmDeleteElement={() =>
						handleDeleteDocument(
							idOfPostulationFileToDelete,
							idOfFormFileToDelete
						)
					}
					closeMenu={setShowDeleteModal}
					modalTitle={'Eliminar Documento'}
					modalTextOne={
						'¿Seguro que deseas eliminar permanentemente el documento?'
					}
				/>
			)}
			{showDropZoneModal && (
				<div>
					<DropZoneModalDocument
						showDropZoneModal={showDropZoneModal}
						setShowDropZoneModal={setShowDropZoneModal}
						dropZoneFiles={dropZoneFiles}
						clearDropzone={clearDropzone}
						setDropZoneFiles={setDropZoneFiles}
						maxFiles={1}
						updateFiles={updateFiles}
						handleDelete={handleDeleteDropZone}
					/>
				</div>
			)}
		</TemplateWithNavBar>
	);
};

export default PostulationForm;
