//3rd party
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

//BL

import {
	usePutCallStatus,
	useDeleteCall,
	useDownloadExcel,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';
import {
	dateLongFormat,
	dateTimeLongFormat,
} from '../../../BusinessLogic/helpers/dateFormats';
import { getPortfolios } from '../../../BusinessLogic/redux/reducers/portfolio';
import { getArtworks } from '../../../BusinessLogic/redux/reducers/artwork';
import { getCollections } from '../../../BusinessLogic/redux/reducers/collection';
import { usePostCreatePostulation } from '../../../BusinessLogic/hooks/query/useQueryPostulation';
import {
	useGetAllPostulationsByCall,
	useGetPostulationByArtistAndCall,
} from '../../../BusinessLogic/hooks/query/useQueryPostulation';

//UI
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import ButtonLinkBack from '../../../UI/atoms/Buttons/Link/ButtonLinkBack';
import TextMainTitle from '../../../UI/molecules/Text/TextMainTitle';
import ButtonLinkBackText from '../../../UI/molecules/Buttons/Link/ButtonLinkBackText';
import TextContentSecond from '../../../UI/atoms/Text/Content/TextContentSecond';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import TextTitleSub from '../../../UI/atoms/Text/Titles/TextTitleSub';
import CardDropdown from '../../../UI/molecules/Card/CardDropdown';
import CardTextIcon from '../../../UI/molecules/Card/CardTextIcon';
import CardLink from '../../../UI/molecules/Card/CardLink';
import CardPostulationSingle from '../../../UI/molecules/Card/CardPostulationSingle';
import { ReactComponent as StarIcon } from '../../../UI/assets/icons/StarIcon.svg';
import { ReactComponent as MoneyIcon } from '../../../UI/assets/icons/MoneyIcon.svg';
import SharePortfolio from '../portfolio/SharePortfolio';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import ButtonFabAdmin from '../../../UI/atoms/Buttons/FAB/ButtonFabAdmin';

import UnderlineHr from '../../../UI/atoms/UnderLine/UnderlineHr';
import TextContentPrincipal from '../../../UI/atoms/Text/Content/TextContentPrincipal';

import PencilGreen from '../../../UI/assets/icons/pencilGreen.svg';
import CrossRed from '../../../UI/assets/icons/CrossRed.svg';
import DeleteYellow from '../../../UI/assets/icons/DeleteYellow.svg';
import ShareNetworkPurple from '../../../UI/assets/icons/ShareNetworkPurple.svg';
import ArrowDownload from '../../../UI/assets/icons/ArrowDownload.svg';
import ViewModal from '../../../UI/templates/ViewModal';
import WarningModal from '../postulation/WarningModal';
import CallPostulations from '../../../UI/organisms/Call/CallPostulations';
import SEO from '../../../UI/molecules/SEO/SEO';
import ModalCallToRegister from '../../../UI/organisms/PopUp/ModalCallToRegister';
import ModalCallGalleryPostulation from '../postulation/ModalCallGalleryPostulation';
import TextContentThird from '../../../UI/atoms/Text/Content/TextContentThird';
import { InputGreenCheckboxAlt } from '../../../UI/atoms/Inputs/InputGreenCheckboxAlt';

/**
 * @description renders single call content information and approve button
 * @param {*} param0
 * @returns
 */

const SingleCallView = ({ callToDisplay, setIsEditing }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { id, type } = callToDisplay;

	const { artworks } = useSelector((state) => state.artworks);
	const { portfolios } = useSelector((state) => state.portfolios);
	const { collections } = useSelector((state) => state.collections);
	const { appUser } = useSelector((state) => state.appUser);
	const { role_company, organization_user_id, main_artist_id } = appUser;

	const { callPostulations, isLoading } = useGetAllPostulationsByCall({
		role_company,
		callId: callToDisplay.id,
	});

	const callPostulationsLength = callPostulations?.length ?? 0;

	const isArtistUser = appUser.role_company === 'ARTIST';
	const isCatalogueArtistUser = appUser.role_company === 'ARTIST_CATALOGATION';
	const isGalleryUser = appUser.role_company === 'GALLERY';
	const isOrganizationUser = appUser.role_company === 'ORGANIZATION';

	const downloadExcelMutation = useDownloadExcel();
	const deleteCallMutation = useDeleteCall();
	const putCallStatusMutation = usePutCallStatus();
	const postCreatePostulationMutation = usePostCreatePostulation();
	const { postulationSingle } = useGetPostulationByArtistAndCall({
		call_id: id,
		organization_user_id,
		artist_id: !isGalleryUser && main_artist_id,
	});

	const [showShareCall, setShowShareCall] = useState(false);
	const [showPostulations, setShowPostulations] = useState(isOrganizationUser);
	const [postulationArtistStatus, setPostulationArtistStatus] =
		useState('not_postulated');
	const [showChangeCallStatusModal, setShowChangeCallStatusModal] =
		useState(false);
	const [showDeleteCallModal, setShowDeleteCallModal] = useState(false);
	const [showRequirementsModal, setShowRequirementsModal] = useState(false);
	const [requirementsModalMessages, setRequirementsModalMessages] = useState({
		buttonMessage: '',
		mainMessage: '',
		redirect: '',
	});
	const [showCallToModalRegister, setShowCallToModalRegister] = useState(false);
	const [showOrganizationModal, setShowOrganizationModal] = useState(false);
	const [showDownloadExcelModal, setShowDownloadExcel] = useState(false);
	const [countryResidence, setCountryResidence] = useState(false);

	const isCallOpen = callToDisplay.status === 'OPEN';

	const isOnlyListing = callToDisplay?.only_listing;

	const isCallOpenForArtistOrCatalogueArtistUser =
		(isArtistUser || isCatalogueArtistUser) && isCallOpen;

	const isCallOpenForGalleryUser = isGalleryUser && isCallOpen;

	useEffect(() => {
		dispatch(getArtworks());
		dispatch(getPortfolios());
		dispatch(getCollections());
	}, [dispatch]);

	useEffect(() => {
		if (postulationSingle && role_company !== 'GALLERY') {
			switch (postulationSingle.status) {
				case 'NEW':
				case 'DRAFT':
					setPostulationArtistStatus('draft');
					break;
				case 'APPROVED':
				case 'REJECTED':
				case 'REVIEWED':
				case 'SAVED':
				case 'READ':
				case 'SUBMITTED':
					setPostulationArtistStatus('postulated');
					break;
				default:
					setPostulationArtistStatus('not_postulated');
			}
		}
	}, [postulationSingle, role_company]);

	const handleChangeCallStatus = () => {
		putCallStatusMutation
			.mutateAsync({
				id,
				status: 'CLOSED',
			})
			.then(() => {
				navigate('/convocatorias');
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const downloadExcelOptions = () => {
		return (
			<div className='px-3'>
				<TextContentThird
					text={t('El documento en formato CSV incluye:')}
					design='text-center'
				/>
				<TextContentThird
					text={t('Nombre, Apellido, Mail, Link del portfolio, Estado')}
					design='text-center !font-bold'
				/>

				<div className='mt-4 flex flex-col items-center justify-center gap-2'>
					<TextContentThird
						text={t('Selecciona los campos opcionales si deseas incluir:')}
						design='text-center'
					/>
					<InputGreenCheckboxAlt
						id='checkbox-country-residence'
						text={t('País de residencia')}
						design='flex flex-row items-center gap-2'
						onChange={() => setCountryResidence(!countryResidence)}
						checked={countryResidence}
					/>
				</div>
			</div>
		);
	};

	const handleDownloadExcel = async () => {
		try {
			await downloadExcelMutation.mutateAsync({
				id,
				country_residence: countryResidence,
				name: callToDisplay.name,
			});
		} catch (error) {
			console.error(error);
		} finally {
			setShowDownloadExcel(false);
		}
	};

	const handleDeleteCall = () => {
		deleteCallMutation
			.mutateAsync(id)
			.then(() => {
				navigate('/convocatorias');
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const postulationButtonAction = async () => {
		switch (postulationArtistStatus) {
			case 'draft':
				navigate(`/postulaciones/${postulationSingle.id}`);
				sessionStorage.setItem('previousPage', 'singleCall');
				break;
			case 'postulated':
				navigate(`/postulaciones/${postulationSingle.id}`);
				sessionStorage.setItem('previousPage', 'singleCall');
				break;
			default:
				const res = await postCreatePostulationMutation.mutateAsync({
					call_id: id,
					organization_user_id,
					artist_id: appUser.main_artist_id,
				});
				if (res?.id) {
					navigate(`/postulaciones/${res.id}`);
				}
		}
	};

	const getBenefitIcon = (benefitType) => {
		switch (benefitType) {
			case 'ECONOMICO':
				return <MoneyIcon />;
			default:
				return <StarIcon />;
		}
	};

	const getSubmitButtonText = (postulationStatus) => {
		switch (postulationStatus) {
			case 'draft':
				return 'Continuar postulación';
			case 'postulated':
				return 'Ver postulación';
			case 'not_postulated':
			default:
				return t('Postularse');
		}
	};

	const artistHasAllRequirements = () => {
		const callIsPortfolioType = callToDisplay.has_portfolio && type === 'FREE';
		const callIsCatalogueType =
			callToDisplay.has_portfolio && type === 'CATALOGUE';

		const hasArtworks = Array.isArray(artworks) && artworks.length > 0;
		const hasPortfolios = Array.isArray(portfolios) && portfolios.length > 0;
		const hasCollectionsWithArtworks =
			Array.isArray(collections) &&
			collections.length > 0 &&
			collections.find((collection) => collection.artworks?.length > 0);
		const hasSubCollectionsWithArtworks =
			Array.isArray(collections) &&
			collections.length > 0 &&
			collections.some(
				(collection) => collection.has_subcollection_artwork === 1
			);

		if (callIsCatalogueType && !hasArtworks) {
			setRequirementsModalMessages({
				mainMessage:
					'Para aplicar a esta convocatoria necesitas primero subir tus obras en Datarte.',
				buttonMessage: 'Crear obras',
				redirect: '/obras/new',
			});
			return false;
		} else if (callIsPortfolioType) {
			if (!hasArtworks) {
				setRequirementsModalMessages({
					mainMessage:
						'Para aplicar a esta convocatoria necesitas primero subir tus obras en Datarte, después crear una colección con tus obras y, finalmente, poder crear tu portafolio.',
					buttonMessage: 'Crear obras',
					redirect: '/obras/new',
				});
				return false;
			}
			if (!hasSubCollectionsWithArtworks && !hasCollectionsWithArtworks) {
				setRequirementsModalMessages({
					mainMessage:
						'Para aplicar a esta convocatoria necesitas crear una colección con tus obras y, finalmente, poder crear tu portafolio.',
					buttonMessage: 'Crear colección',
					redirect: '/colecciones/new',
				});
				return false;
			}
			if (!hasPortfolios) {
				setRequirementsModalMessages({
					mainMessage:
						'Para aplicar a esta convocatoria necesitas crear tu portafolio.',
					buttonMessage: 'Crear portafolio',
					redirect: '/portafolios/plantillas',
				});
				return false;
			}
		}
		return true;
	};

	return (
		<TemplateWithNavBar>
			<SEO
				title={`Convocatoria de ${
					callToDisplay.call_organization_name ?? ''
				} : ${callToDisplay.name ?? ''}`}
				description={`Convocatoria organizada por ${
					callToDisplay.call_organization_name ?? ''
				} de ${callToDisplay.city ?? ''}, ${
					callToDisplay.call_country ?? ''
				}. ${callToDisplay.sub_header ?? ''}`}
				ogTitle={`Convocatoria de ${
					callToDisplay.call_organization_name ?? ''
				} : ${callToDisplay.name ?? ''}`}
				ogDescription={`Convocatoria organizada por ${
					callToDisplay.call_organization_name ?? ''
				} de ${callToDisplay.city ?? ''}, ${
					callToDisplay.call_country ?? ''
				}. ${callToDisplay.sub_header ?? ''}`}
				ogImage={callToDisplay.main_picture_url}
			/>
			<div>
				<div className='relative flex w-full flex-col'>
					<div className='relative mt-8 flex justify-center'>
						<ButtonLinkBack
							redirect={'/convocatorias'}
							design='absolute left-0 md:hidden'
						/>
						<ButtonLinkBackText
							textBack={'Regresar a convocatorias'}
							design='hidden absolute left-0 md:block md:w-1/4'
							textBackDesign='pl-3 text-purple-50'
							redirect={'/convocatorias'}
							arrowLeftColor='purple'
						/>
						<TextMainTitle
							title={callToDisplay.name}
							textColor='text-purple-50'
							color='bg-purple-50'
							design='text-center flex flex-col w-4/5 md:w-1/2'
						/>
					</div>
				</div>

				<CardPostulationSingle
					logo={callToDisplay.call_organization_image}
					title={callToDisplay.name}
					image={callToDisplay.main_picture_url}
					category={callToDisplay.category}
					dateLimit={dateLongFormat(callToDisplay.closes_at)}
					technique={callToDisplay.technique}
					organization={callToDisplay.call_organization_name}
					country={callToDisplay.call_organization_country}
					city={callToDisplay.city}
					status={callToDisplay.status}
					hasAlliance={callToDisplay.has_datarte_alliance}
				/>

				{isOrganizationUser && (
					<>
						<section className='flex flex-col items-center justify-center gap-4 py-4 md:flex-row-reverse lg:gap-20'>
							<div className='flex w-full flex-col-reverse items-center justify-between gap-4 lg:flex-row lg:py-2'>
								<div className='my-4 flex w-full flex-row justify-between gap-2 lg:w-2/5'>
									<span
										className='cursor-pointer'
										onClick={() => setShowPostulations(false)}
									>
										<TextContentPrincipal
											design={
												showPostulations
													? 'text-gray-300'
													: 'underline underline-offset-4'
											}
											text={'Información'}
											style={{ fontWeight: 400 }}
										/>
									</span>
									<span
										className='cursor-pointer'
										onClick={() => setShowPostulations(true)}
									>
										<TextContentPrincipal
											design={
												showPostulations
													? 'underline underline-offset-4'
													: 'text-gray-300'
											}
											text={`${callPostulationsLength} ${
												callPostulationsLength === 1
													? 'postulación'
													: 'postulaciones'
											} `}
											style={{ fontWeight: 400 }}
										/>
									</span>
								</div>
								<div
									className={`grid w-full grid-cols-2 gap-4  md:px-0 lg:w-fit-content ${
										callPostulationsLength > 0
											? 'xs:grid-cols-5'
											: 'xs:grid-cols-4'
									}`}
								>
									<ButtonFabAdmin
										icon={PencilGreen}
										label={'Editar'}
										textColor={'text-green-50'}
										containerDesign={'flex !w-full md:!flex-row lg:!w-28'}
										action={() => {
											setIsEditing(true);
										}}
									/>
									<ButtonFabAdmin
										icon={CrossRed}
										label={'Finalizar'}
										textColor={'text-strong_red-50'}
										containerDesign={'flex !w-full md:!flex-row lg:!w-28'}
										action={() => setShowChangeCallStatusModal(true)}
									/>
									<ButtonFabAdmin
										icon={DeleteYellow}
										label={'Borrar'}
										textColor={'text-yellow-50'}
										containerDesign={'flex !w-full md:!flex-row lg:!w-28'}
										action={() => setShowDeleteCallModal(true)}
									/>
									<ButtonFabAdmin
										icon={ShareNetworkPurple}
										label={'Compartir'}
										textColor={'text-purple-50'}
										containerDesign={'flex !w-full md:!flex-row lg:!w-28'}
										action={() => setShowShareCall(true)}
									/>
									{callPostulationsLength > 0 && (
										<ButtonFabAdmin
											icon={ArrowDownload}
											label={'Descargar'}
											textColor={'text-purple-50'}
											containerDesign={'flex !w-full md:!flex-row lg:!w-28'}
											action={() => setShowDownloadExcel(true)}
										/>
									)}
								</div>
							</div>
						</section>
						<UnderlineHr colour={'purple'} design={'hidden md:block'} />
					</>
				)}

				{showPostulations ? (
					<CallPostulations
						callPostulations={callPostulations}
						isLoading={isLoading}
					/>
				) : (
					<>
						<div className='mt-4 flex w-full flex-col gap-4'>
							<section>
								<TextTitleSub
									title='Descripción'
									textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2'
									style={{
										fontWeight: '700',
									}}
								/>
								<TextContentSecond
									text={callToDisplay.header}
									design={'break-words whitespace-pre-line w-full py-4'}
								/>
							</section>
							<section>
								<TextTitleSub
									title='Requerimientos'
									textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2'
									style={{
										fontWeight: '700',
									}}
								/>
								<TextContentSecond
									text={callToDisplay.requirements}
									design={'break-words whitespace-pre-line w-full py-4'}
								/>
							</section>
							<section>
								<TextTitleSub
									title='Detalles'
									textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
									style={{
										fontWeight: '700',
									}}
								/>

								<>
									{callToDisplay.has_benefit && (
										<CardDropdown
											title={'Beneficios'}
											titleDesign={'text-purple-50 font-bold'}
											titleStyle={{
												fontWeight: '700',
											}}
											disableDropdown={true}
										>
											<div className='flex w-full grid-cols-3 flex-col flex-wrap md:grid md:gap-4'>
												{callToDisplay.benefits?.map((benefit, index) => (
													<div
														key={index}
														className='flex w-full items-start gap-4'
													>
														<div className='flex aspect-square w-16 flex-col items-center justify-start py-4'>
															{getBenefitIcon(benefit.benefit_type)}
														</div>
														<CardTextIcon
															title={benefit.benefit_name}
															titleDesign={'font-normal'}
															description={benefit.benefit_description}
															componentDesign='w-full'
															titleStyle={{ fontWeight: '500' }}
														/>
													</div>
												))}
											</div>
										</CardDropdown>
									)}
								</>

								<CardDropdown
									title={'Fechas importantes'}
									titleDesign={'text-purple-50'}
									titleStyle={{
										fontWeight: '700',
									}}
									disableDropdown
								>
									<div className='flex w-full grid-cols-3 flex-col flex-wrap md:grid md:gap-4'>
										<CardTextIcon
											title={'Apertura'}
											titleDesign={'font-normal'}
											description={dateTimeLongFormat(callToDisplay.opens_at)}
											componentDesign='w-full'
											titleStyle={{ fontWeight: '500' }}
										/>
										<CardTextIcon
											title={'Cierre'}
											titleDesign={'font-normal'}
											description={dateTimeLongFormat(callToDisplay.closes_at)}
											componentDesign='w-full'
											titleStyle={{ fontWeight: '500' }}
										/>
										<CardTextIcon
											title={'Resultados'}
											titleDesign={'font-normal'}
											description={dateTimeLongFormat(callToDisplay.results_at)}
											componentDesign='w-full'
											titleStyle={{ fontWeight: '500' }}
										/>
									</div>
								</CardDropdown>

								<>
									{callToDisplay.judge_is_public && callToDisplay.has_judge && (
										<CardDropdown
											title={'Jurados'}
											titleDesign={'text-purple-50'}
											titleStyle={{
												fontWeight: '700',
											}}
										>
											<div className='flex w-full grid-cols-3 flex-col flex-wrap md:grid md:gap-4'>
												{callToDisplay.judges?.map((judge, index) => (
													<CardTextIcon
														title={judge.judge_name}
														description={judge.judge_description}
														image={judge.judge_main_picture}
														componentDesign='w-full'
														key={index}
														titleStyle={{ fontWeight: '500' }}
													/>
												))}
											</div>
										</CardDropdown>
									)}
								</>
							</section>

							{(callToDisplay.call_organization_name ||
								callToDisplay.call_organization_description ||
								callToDisplay.call_organization_email ||
								callToDisplay.call_organization_phone ||
								callToDisplay.call_organization_website) && (
								<section>
									<TextTitleSub
										title='Organizado por'
										textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
										style={{
											fontWeight: '700',
										}}
									/>
									<div className='flex items-center gap-4 py-2'>
										{callToDisplay.call_organization_image &&
											callToDisplay.call_organization_name && (
												<LazyLoadImage
													alt={callToDisplay.call_organization_name}
													src={callToDisplay.call_organization_image}
													effect='blur'
													wrapperClassName='w-12 aspect-square rounded-full flex items-center lazyLoadingImages overflow-hidden'
													className='max-h-full object-contain'
													delayTime='0'
													threshold='800'
												/>
											)}
										{callToDisplay.call_organization_name && (
											<TextContentSecond
												text={callToDisplay.call_organization_name}
											/>
										)}
									</div>
									{callToDisplay.call_organization_description && (
										<div className='flex w-full flex-col gap-4 py-2'>
											<TextContentSecond
												text={'Descripción'}
												style={{ fontWeight: 500 }}
											/>
											<TextContentSecond
												text={callToDisplay.call_organization_description}
											/>
										</div>
									)}
									<div className='flex w-full grid-cols-3 flex-col flex-wrap md:grid md:gap-6'>
										{callToDisplay.call_organization_email && (
											<CardTextIcon
												title={'Email'}
												description={callToDisplay.call_organization_email}
												titleStyle={{ fontWeight: '500' }}
											/>
										)}
										{callToDisplay.call_organization_phone && (
											<CardTextIcon
												title={'Contacto'}
												description={callToDisplay.call_organization_phone}
												titleStyle={{ fontWeight: '500' }}
											/>
										)}
										{callToDisplay.call_organization_website && (
											<CardTextIcon
												title={'Página web'}
												description={callToDisplay.call_organization_website}
												titleStyle={{ fontWeight: '500' }}
											/>
										)}
									</div>
								</section>
							)}

							{(callToDisplay.has_portfolio ||
								callToDisplay.has_form ||
								callToDisplay.has_cv) && (
								<section>
									<TextTitleSub
										title='Documentos requeridos'
										textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
										style={{
											fontWeight: '700',
										}}
									/>
									<div
										className='flex w-full flex-col gap-4'
										data-test={'call-requirements-section'}
									>
										<div className='flex w-full flex-col gap-4'>
											{callToDisplay.has_portfolio &&
												callToDisplay.type === 'CATALOGUE' && (
													<CardLink
														title={'Catálogo'}
														text={`Para aplicar a esta convocatoria debes seleccionar tus obras subidas a Datarte.`}
														titleStyle={{ fontWeight: '500' }}
													/>
												)}
											{callToDisplay.has_portfolio &&
												callToDisplay.type === 'FREE' && (
													<CardLink
														title={'Portafolio'}
														text={`Para aplicar a esta convocatoria debes seleccionar un portafolio creado en Datarte.`}
														titleStyle={{ fontWeight: '500' }}
													/>
												)}
											{callToDisplay.has_form && (
												<CardLink
													title={'Formulario'}
													text={`Para aplicar a esta convocatoria debes diligenciar un formulario.`}
													titleStyle={{ fontWeight: '500' }}
												/>
											)}
											{callToDisplay.has_cv && (
												<CardLink
													title={'Curriculum vitae'}
													text={`Para aplicar a esta convocatoria debes adjuntar tu curriculum vitae.`}
													titleStyle={{ fontWeight: '500' }}
												/>
											)}
										</div>
									</div>
								</section>
							)}

							<section className='pb-10 pt-4'>
								<TextTitleSub
									title='Términos y condiciones'
									textColor='text-purple-50 w-full border-b-2 border-purple pt-4 pb-2 mb-4'
									style={{
										fontWeight: '700',
									}}
								/>
								<a
									href={'https://www.datarte.art/tycdatarte'}
									target='_blank'
									rel='noreferrer'
								>
									<TextContentSecond
										text={'Términos y condiciones de uso.'}
										design={'text-[#34B3E5] underline'}
									/>
								</a>
							</section>

							{isCallOpenForArtistOrCatalogueArtistUser && !isOnlyListing && (
								<div
									data-test={'singleCall-action-button'}
									className='sticky top-0 z-20 mb-10 flex w-full flex-col bg-white px-0 pb-5'
								>
									<ButtonFabExtendedPrimary
										className='self-center text-white'
										text={getSubmitButtonText(postulationArtistStatus)}
										action={() => {
											if (artistHasAllRequirements()) {
												postulationButtonAction();
											} else {
												setShowRequirementsModal(true);
											}
										}}
									/>
								</div>
							)}
							{isCallOpenForGalleryUser && !isOnlyListing && (
								<div className='sticky top-0 z-20 mb-10 flex w-full flex-col bg-white px-0 pb-5'>
									<ButtonFabExtendedPrimary
										className='self-center text-white'
										text={t('Postularse')}
										action={() => setShowOrganizationModal(true)}
									/>
								</div>
							)}
						</div>
						{!appUser.token && isCallOpen && !isOnlyListing && (
							<div className='mb-10 flex w-full justify-center'>
								<ButtonFab
									label={t('Postularse')}
									size={'large'}
									action={() => setShowCallToModalRegister(true)}
								/>
							</div>
						)}
					</>
				)}

				{showShareCall && (
					<div
						className='fixed right-0 top-0 z-40 flex h-full w-full cursor-pointer bg-black-50/70 bg-opacity-70 shadow-xl'
						onClick={(e) => {
							e.stopPropagation();
						}}
					>
						<SharePortfolio
							title={'Compartir convocatoria'}
							share={showShareCall}
							setShare={setShowShareCall}
							designTwo={
								'fixed inset-0 bg-black-50 bg-opacity-75 transition-opacity'
							}
						/>
					</div>
				)}

				{showRequirementsModal && (
					<WarningModal
						title={'Recuerda'}
						textPrimary={requirementsModalMessages.mainMessage}
						textSecondary={
							'Una vez realices este proceso, te invitamos a volver a esta página, donde podrás postularte a la convocatoria.'
						}
						mainButtonTitle={requirementsModalMessages.buttonMessage}
						secondaryButtonTitle='Regresar'
						closeModalFunction={setShowRequirementsModal}
						redirect={requirementsModalMessages.redirect}
					/>
				)}
				{showChangeCallStatusModal && (
					<ViewModal
						closeMenu={setShowChangeCallStatusModal}
						modalTitle={'Finalizar convocatoria'}
						modalTextOne={'¿ Seguro que deseas finalizar esta convocatoria ?'}
						setConfirmDeleteElement={() => handleChangeCallStatus()}
						confirmButtonText={'Finalizar'}
					/>
				)}

				{showDeleteCallModal && (
					<ViewModal
						closeMenu={setShowDeleteCallModal}
						modalTitle={'Eliminar convocatoria'}
						modalTextOne={'¿ Seguro que deseas borrar esta convocatoria ?'}
						setConfirmDeleteElement={() => handleDeleteCall()}
					/>
				)}

				{showDownloadExcelModal && (
					<ViewModal
						closeMenu={setShowDownloadExcel}
						modalTitle={'Descargar Convocatoria'}
						customChildren={downloadExcelOptions()}
						confirmButtonText={'Descargar'}
						confirmButtonTextDesign={'!text-green-50'}
						setConfirmDeleteElement={() => handleDownloadExcel()}
					/>
				)}

				{!appUser.token && showCallToModalRegister && (
					<ModalCallToRegister
						showModalCallToRegister={showCallToModalRegister}
						setShowCallToRegister={setShowCallToModalRegister}
					/>
				)}
				{showOrganizationModal && (
					<ModalCallGalleryPostulation
						hideModal={() => setShowOrganizationModal(false)}
						callToDisplay={callToDisplay}
					/>
				)}
			</div>
		</TemplateWithNavBar>
	);
};

export default SingleCallView;
