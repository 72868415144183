import { useState, useEffect } from 'react';

export const breakpoints = {
	xs: '(max-width: 639px)',
	sm: '(min-width: 640px)',
	md: '(min-width: 768px)',
	lg: '(min-width: 1024px)',
	xl: '(min-width: 1280px)',
	'2xl': '(min-width: 1536px)',
};

export const useMediaQuery = (query) => {
	const mediaQuery = breakpoints[query] || query;

	const [matches, setMatches] = useState(() => {
		if (typeof window !== 'undefined') {
			const result = window.matchMedia(mediaQuery).matches;
			return result;
		}
		return false;
	});

	useEffect(() => {
		if (typeof window === 'undefined') return;

		const mediaQueryList = window.matchMedia(mediaQuery);

		setMatches(mediaQueryList.matches);

		const handleChange = (event) => {
			setMatches(event.matches);
		};

		mediaQueryList.addEventListener('change', handleChange);

		return () => {
			mediaQueryList.removeEventListener('change', handleChange);
		};
	}, [mediaQuery, query]);

	return matches;
};

export const useIsMobile = () => {
	const isMobile = useMediaQuery('xs');
	return isMobile;
};
export const useIsTablet = () => useMediaQuery('sm');
export const useIsDesktop = () => useMediaQuery('md');
export const useIsLargeScreen = () => useMediaQuery('lg');
export const useIsExtraLargeScreen = () => useMediaQuery('xl');
export const useIs2XLargeScreen = () => useMediaQuery('2xl');
