import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetAppUser } from '../redux/reducers/appUser';

export function useCheckStorageVersion(version) {
	const dispatch = useDispatch();

	useEffect(() => {
		localStorage.getItem('lng');
		const appVersion = localStorage.getItem('APP_VERSION');
		if (appVersion && appVersion !== version) {
			dispatch(resetAppUser());
			localStorage.clear();
			window.location.reload();
			localStorage.setItem('APP_VERSION', version);
			return;
		}

		if (appVersion === 'undefined' || appVersion === null) {
			localStorage.setItem('APP_VERSION', version);
		}
	}, [version, dispatch]);
}
