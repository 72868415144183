import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ENDPOINTS_POSTULATION } from '../../helpers/routes';
import { fetchGetData } from '../fetchGetData';
import { fetchPostData } from '../fetchPostData';
import { fetchPostFiles } from '../fetchPostFiles';
import { fetchDeleteElement } from '../fetchDeleteElement';
import { fetchPutData } from '../fetchPutData';

//FETCH ALL POSTULATIONS BY ROLE
const fetchAllPostulationsByRole = async (getValues) => {
	const { organization_user_id, role_company } = getValues;
	if (!organization_user_id) return;
	let endpoint = '';
	if (
		role_company === 'ARTIST' ||
		role_company === 'GALLERY' ||
		role_company === 'ARTIST_CATALOGATION'
	) {
		endpoint = `${ENDPOINTS_POSTULATION.GET_POSTULATION_ALL_ARTIST}${organization_user_id}/`;
	}
	if (role_company === 'ORGANIZATION') {
		endpoint = `${ENDPOINTS_POSTULATION.GET_ORGANIZATION_ALL_POSTULATION}${organization_user_id}/`;
	}

	const response = await fetchGetData(endpoint);
	return response;
};

export const useGetAllPostulationsByRole = (getValues) => {
	const { data, isError, error, isLoading } = useQuery({
		queryKey: ['postulations'],
		queryFn: () => fetchAllPostulationsByRole(getValues),
	});
	return {
		postulations: data?.postulations,
		isLoading,
		isError,
		error,
	};
};
//FETCH ALL POSTULATIONS BY CALL
const fetchAllPostulationsByCall = async (getValues) => {
	const { callId, role_company } = getValues;
	const { organization_user_id } = localStorage;

	if (!callId || !organization_user_id) return;
	if (
		role_company === 'ARTIST_CATALOGATION' ||
		role_company === 'ARTIST' ||
		role_company === 'GALLERY' ||
		!role_company
	) {
		return;
	}
	const endpoint = `${ENDPOINTS_POSTULATION.GET_POSTULATION_CALL}${callId}/`;

	const response = await fetchGetData(endpoint);
	return response;
};

export const useGetAllPostulationsByCall = (getValues) => {
	const { callId, role_company } = getValues;
	const { data, isError, error, isLoading } = useQuery({
		queryKey: ['postulations', callId],
		queryFn: () => fetchAllPostulationsByCall(getValues),
		enabled: !!callId && !!role_company,
	});
	return {
		callPostulations: data,
		isLoading,
		isError,
		error,
	};
};

//GET POSTULATION SINGLE
const fetchPostulationById = async (postulationId) => {
	if (!postulationId) return;
	const endpoint = `${ENDPOINTS_POSTULATION.GET_POSTULATION}${postulationId}/`;
	const response = await fetchGetData(endpoint);
	return response;
};

export const useGetPostulationSingle = (postulationId) => {
	const { data, isError, error, isLoading } = useQuery({
		queryKey: ['postulations', postulationId],
		queryFn: () => fetchPostulationById(postulationId),
	});
	return {
		postulationSingle: data,
		isLoading,
		isError,
		error,
	};
};

// Get postulation by artist and call

const fetchPostulationByArtistAndCall = async (postValues) => {
	if (
		!postValues.call_id ||
		!postValues.organization_user_id ||
		!postValues.artist_id
	)
		return;
	const endpoint =
		ENDPOINTS_POSTULATION.GET_POSTULATION_DATA_BY_CALL_AND_ORGANIZATION_USER_ID;
	const response = await fetchPostData(endpoint, postValues);

	return response;
};

export const useGetPostulationByArtistAndCall = (postValues) => {
	const { data, isError, error, isLoading } = useQuery({
		queryKey: [
			'postulationsByCall',
			postValues.call_id,
			postValues.artist_id ?? postValues.organization_user_id,
		],
		queryFn: () => fetchPostulationByArtistAndCall(postValues),
		staleTime: 300000, // 5 minutes
		cacheTime: 600000, // 10 minutes
		enabled:
			!!postValues.call_id &&
			!!(postValues.artist_id || postValues.organization_user_id),
	});
	return {
		postulationSingle: data,
		isLoading,
		isError,
		error,
	};
};

// POST POST POSTULATION FORM

const postPostulationForm = async (values) => {
	if (!values) return;
	const endpoint = `${ENDPOINTS_POSTULATION.POST_POSTULATION_APPLICATION_FORM}`;
	const response = await fetchPostData(endpoint, values);
	return response;
};

export const usePostPostulationForm = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (values) => {
			return postPostulationForm(values);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['postulationsByCall'],
			});
		},
	});
};

const postPostulationFormDocument = async (values) => {
	if (!values) return;
	const endpoint =
		ENDPOINTS_POSTULATION.POST_POSTULATION_APPLICATION_FORM_DOCUMENT;
	const response = await fetchPostFiles(endpoint, values);
	return response;
};

export const usePostPostulationFormDocument = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (values) => {
			return postPostulationFormDocument(values);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['postulationsByCall'],
			});
		},
	});
};

const postPostulationDocument = async (values) => {
	if (!values) return;

	const endpoint = ENDPOINTS_POSTULATION.POST_POSTULATION_DOCUMENT;

	const response = await fetchPostFiles(endpoint, values);
	return response;
};

export const usePostPostulationDocument = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (values) => {
			return postPostulationDocument(values);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['postulationsByCall'],
			});
		},
	});
};

// POST POSTULATION CATALOGUE

const postPostulationCatalogue = async (values) => {
	if (!values) return;
	const endpoint = ENDPOINTS_POSTULATION.POST_POSTULATION_CATALOGUE;
	const response = await fetchPostData(endpoint, values);
	return response;
};

export const usePostPostulationCatalogue = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (values) => {
			return postPostulationCatalogue(values);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['postulationsByCall'],
			});
		},
	});
};

// POST POSTULATION PORTFOLIO

const postPostulationPortfolio = async (values) => {
	if (!values) return;
	const endpoint = ENDPOINTS_POSTULATION.POST_POSTULATION_PORTFOLIO;
	const response = await fetchPostData(endpoint, values);
	return response;
};

export const usePostPostulationPortfolio = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (values) => {
			return postPostulationPortfolio(values);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['postulationsByCall'],
			});
		},
	});
};

const postCreatePostulation = async (values) => {
	if (!values) return null;
	const endpoint = ENDPOINTS_POSTULATION.POST_CREATE_POSTULATION;
	const response = await fetchPostData(endpoint, values);
	return response;
};

export const usePostCreatePostulation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (values) => {
			return postCreatePostulation(values);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['postulations'],
			});
		},
	});
};

const deletePostulationFormDocument = async (id) => {
	if (!id) {
		return;
	}
	const endpoint = `${ENDPOINTS_POSTULATION.DELETE_POSTULATION_FORM_DOCUMENT}${id}`;
	const response = await fetchDeleteElement(endpoint);
	return response;
};

export const useDeletePostulationFormDocument = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (id) => {
			return deletePostulationFormDocument(id);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['postulations'],
			});
		},
	});
};

//DELETE POSTULATION DOCUMENT

const deleteDocumentFromPostulation = async (id) => {
	if (!id) {
		return;
	}
	const endpoint = `${ENDPOINTS_POSTULATION.DELETE_POSTULATION_DOCUMENT}${id}`;
	const response = await fetchDeleteElement(endpoint);
	return response;
};

export const useDeleteDocumentFromPostulation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (id) => {
			return deleteDocumentFromPostulation(id);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['postulations'],
			});
		},
	});
};

//PUT POSTULATION:

const putPostulation = async (input) => {
	if (!input) {
		return;
	}
	const { values, id } = input;
	const endpoint = `${ENDPOINTS_POSTULATION.PUT_POSTULATION}${id}/`;
	const response = await fetchPutData(endpoint, values);
	return response;
};

export const usePutPostulation = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (input) => {
			return putPostulation(input);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['postulations'],
			});
		},
	});
};

//PUT POSTULATION FORM
const putPostulationForm = async (values) => {
	if (!values) {
		return;
	}

	const endpoint = ENDPOINTS_POSTULATION.PUT_POSTULATION_APPLICATION_FORM;
	const response = await fetchPutData(endpoint, values);
	return response;
};

export const usePutPostulationForm = () => {
	return useMutation({
		mutationFn: (values) => {
			return putPostulationForm(values);
		},
	});
};

//PUT POSTULATION STATUS

const putPostulationStatus = async (values) => {
	if (!values) {
		return;
	}

	const endpoint = ENDPOINTS_POSTULATION.PUT_POSTULATION_STATUS;
	const response = await fetchPutData(endpoint, values);
	return response;
};

export const usePutPostulationStatusArtist = () => {
	return useMutation({
		mutationFn: (values) => {
			return putPostulationStatus(values);
		},
	});
};

export const usePutPostulationStatusOrganization = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (values) => {
			return putPostulationStatus(values);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['postulations'],
			});
		},
	});
};

// PUT POSTULATION CATALOGUE

const putPostulationCatalogue = async (values) => {
	if (!values) {
		return;
	}

	const endpoint = ENDPOINTS_POSTULATION.PUT_POSTULATION_CATALOGUE;
	const response = await fetchPutData(endpoint, values);
	return response;
};

export const usePutPostulationCatalogue = () => {
	return useMutation({
		mutationFn: (values) => {
			return putPostulationCatalogue(values);
		},
	});
};
