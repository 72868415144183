import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ButtonHeaderPayments = ({ className = '' }) => {
	const { t } = useTranslation();

	const navigate = useNavigate();
	const { appUser } = useSelector((state) => state.appUser);

	const token = appUser.token;
	const activePlan = appUser.subscription_type;
	const userRole = appUser.role_company;
	const isArtistUser = userRole === 'ARTIST';
	const isActivePlanFree = activePlan === 'PlanGratis' && isArtistUser;

	const handleGoToMemberships = () => {
		navigate('/planes');
	};

	if (!isActivePlanFree && token) {
		return null;
	}

	return (
		<button
			className={`rounded-3xl bg-[#CBBFD9] px-3 py-1 ${className} `}
			onClick={handleGoToMemberships}
		>
			<p className='text-sm text-white lg:text-base'>{t('hazteMiembro')}</p>
		</button>
	);
};

export default ButtonHeaderPayments;
