import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// BL

import {
	usePostPostulationDocument,
	useDeleteDocumentFromPostulation,
} from '../../../BusinessLogic/hooks/query/useQueryPostulation';

import { navbarOptionsArtist } from '../../../BusinessLogic/data/NavbarOptions';
import { useGetPostulationSingle } from '../../../BusinessLogic/hooks/query/useQueryPostulation';

// UI

import ButtonLinkBack from '../../../UI/atoms/Buttons/Link/ButtonLinkBack';
import ButtonLinkBackText from '../../../UI/molecules/Buttons/Link/ButtonLinkBackText';
import TextTitlePrincipal from '../../../UI/atoms/Text/Titles/TextTitlePrincipal';
import ButtonFabExtendedPrimary from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendedPrimary';
import ButtonFab from '../../../UI/atoms/Buttons/FAB/ButtonFab';
import DropZoneModalDocument from '../../../UI/molecules/DropZone/DropZoneModalDocument';
import ButtonWithIcon from '../../../UI/molecules/Buttons/WithIcon/ButtonWithIcon';
import { ReactComponent as PortfolioWhite } from '../../../UI/assets/icons/PortfolioWhite.svg';
import { ReactComponent as DeleteCanGrey } from '../../../UI/assets/icons/DeleteCanGrey.svg';
import Cross from '../../../UI/assets/icons/Cross.svg';
import CheckIcon from '../../../UI/assets/icons/whiteCheck.svg';
import SpinnerWhite from '../../../UI/assets/icons/loadingSpinnerSmallWhite.svg';
import ViewModal from '../../../UI/templates/ViewModal';
import DocumentPreview from '../../../UI/molecules/Document/DocumentPreview';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import PortfolioLoading from '../../../UI/organisms/Loading/PortfolioLoading';

const PostulationCVForm = () => {
	const { id: postulationId } = useParams();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { postulationStatusDocument } = useSelector(
		(state) => state.postulation
	);

	const { postulationSingle, isLoading } =
		useGetPostulationSingle(postulationId);

	const postPostulationDocumentMutation = usePostPostulationDocument();

	const deleteDocumentFromPostulationMutation =
		useDeleteDocumentFromPostulation();

	const { appUser } = useSelector((state) => state.appUser);

	const navbarOptionsArtistToUse = navbarOptionsArtist(appUser);

	const [filesToUpload, setFilesToUpload] = useState([]);
	const [filesToShow, setFilesToShow] = useState([]);
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const [showDropZoneModal, setShowDropZoneModal] = useState(false);

	const [dropZoneFiles, setDropZoneFiles] = useState([]);

	const [idOfPostulationFileToDelete, setIdOfPostulationFileToDelete] =
		useState(null);
	const [idOfFormFileToDelete, setIdOfFormFileToDelete] = useState(null);
	const [loading, setLoading] = useState(false);

	const isArtistUser = appUser.role_company === 'ARTIST';
	const isCatalogueArtistUser = appUser.role_company === 'ARTIST_CATALOGATION';
	const isOrganizationUser = appUser.role_company === 'ORGANIZATION';
	const isGalleryUser = appUser.role_company === 'GALLERY';

	useEffect(() => {
		const arrOfFilesToShow = [];
		if (
			Array.isArray(postulationSingle?.documents) &&
			postulationSingle?.documents.length === 0
		) {
			setFilesToShow(postulationSingle?.documents);
		} else if (postulationSingle?.documents) {
			arrOfFilesToShow.push(postulationSingle?.documents);
			setFilesToShow(arrOfFilesToShow);
		}
	}, [postulationSingle, postulationSingle?.forms]);

	useEffect(() => {
		appUser.role_company !== 'GALLERY' &&
			(navbarOptionsArtistToUse[0].url = `/artistas/${appUser.main_artist_id}`);
	}, [navbarOptionsArtistToUse, appUser]);

	if (isLoading) {
		return <PortfolioLoading />;
	}

	const updateFiles = (incomingFiles) => {
		setDropZoneFiles(incomingFiles);
		setFilesToUpload([incomingFiles[0]]);
		setFilesToShow([incomingFiles[0]]);
	};

	const handleDeleteDropZone = () => {
		setDropZoneFiles([]);
		setFilesToUpload([]);
		setFilesToShow([]);
	};
	const handleDeleteFilesToUpload = () => {
		setFilesToUpload([]);
		setFilesToShow([]);
	};
	const handleDeleteFilesFromPostulation = async (id) => {
		await deleteDocumentFromPostulationMutation.mutateAsync(id);
	};

	const handleDeleteDocument = (
		idOfPostulationFileToDelete,
		idOfFormFileToDelete
	) => {
		handleDeleteFilesToUpload(idOfFormFileToDelete);

		if (Object.entries(postulationSingle.documents).length > 0) {
			handleDeleteFilesFromPostulation(idOfPostulationFileToDelete);
		}
	};

	const clearDropzone = () => {
		setDropZoneFiles([]);
		setShowDropZoneModal(false);
	};

	const onSubmitAction = () => {
		const values = {
			postulation_id: postulationSingle?.id,
			filesArray: filesToUpload[0],
		};
		setLoading(true);
		postPostulationDocumentMutation.mutateAsync(values).then(() => {
			setLoading(false);
			navigate(-1);
		});
	};

	if (
		isOrganizationUser ||
		((isGalleryUser || isArtistUser || isCatalogueArtistUser) &&
			postulationSingle.status !== 'DRAFT')
	) {
		return (
			<div className='container mx-auto px-2 md:px-4 lg:px-8 2xl:px-10'>
				<div className='mt-8 flex md:grid md:grid-cols-3'>
					<ButtonLinkBack
						redirect={`/postulaciones/${postulationSingle?.id}`}
						design='md:hidden'
					/>
					<ButtonLinkBackText
						design='hidden md:block w-full '
						textBack={`${t('RegresarAPostulación')}`}
						textBackDesign='pl-3 text-[#CBBFD9]'
						redirect={`/postulaciones/${postulationSingle?.id}`}
						arrowLeftColor='#CBBFD9'
					/>
					<TextTitlePrincipal
						title={'Curriculum vitae (CV)'}
						textColor='text-[#CBBFD9]'
						color='bg-[#CBBFD9]'
						design='text-center flex flex-col w-full mr-[10%]'
					/>
				</div>

				<div className='my-20'>
					<DocumentPreview
						pdfPreview={`${postulationSingle.documents.document_url}`}
					/>
				</div>
			</div>
		);
	}

	return (
		<TemplateWithNavBar>
			<div className='mt-8 flex md:grid md:grid-cols-3'>
				<ButtonLinkBack
					redirect={`/postulaciones/${postulationSingle?.id}`}
					design='md:hidden'
				/>
				<ButtonLinkBackText
					design='hidden md:block w-full '
					textBack={`${t('RegresarAPostulación')}`}
					textBackDesign='pl-3 text-[#CBBFD9]'
					redirect={`/postulaciones/${postulationSingle?.id}`}
					arrowLeftColor='#CBBFD9'
				/>
				<TextTitlePrincipal
					title={'Curriculum vitae (CV)'}
					textColor='text-[#CBBFD9]'
					color='bg-[#CBBFD9]'
					design='text-center flex flex-col w-full mr-[10%]'
				/>
			</div>

			<div className='mt-8 flex py-8'>
				<div className='m-2'>
					<ButtonWithIcon
						design={'bg-[#9CBDB9] rounded-full p-4'}
						icon={<PortfolioWhite />}
					/>
				</div>
				<div className='m-4'>
					<h2 className='h2-Subtitle text-[#CBBFD9]'> Curriculum vitae (CV)</h2>
					<p className='body-Text2 py-2'>
						Para aplicar a esta convocatoria debes adjuntar tu CV.
					</p>
				</div>
			</div>
			{filesToShow.length >= 1 && (
				<div className='py-8'>
					<p className='py-1 '> Documento adjuntado: </p>

					{filesToShow?.map((element, index) => (
						<div key={index}>
							{filesToShow?.length >= 1 && (
								<div className='flex justify-between'>
									<p className='py-4 text-[#34B3E5]'>
										{element.file ? element.file.name : element.document_name}
									</p>
									<div className='cursor-pointer py-4'>
										<DeleteCanGrey
											onClick={() => {
												setShowDeleteModal(true);
												setIdOfFormFileToDelete(postulationSingle?.id);
												element.document_name &&
													setIdOfPostulationFileToDelete(postulationSingle?.id);
											}}
										/>
									</div>
								</div>
							)}
						</div>
					))}
				</div>
			)}
			{filesToShow?.length < 1 && (
				<div className='flex w-full flex-col items-center'>
					<ButtonFabExtendedPrimary
						colorClass={'bg-[#9CBDB9]'}
						className={'w-fit text-white'}
						text={'Adjuntar CV'}
						typeButton={'button'}
						action={() => {
							filesToShow?.length < 1 && setShowDropZoneModal(true);
						}}
					/>
				</div>
			)}
			{postulationSingle.documents?.document_url ? (
				<div className='my-[20%] flex w-full flex-row justify-center gap-3 md:my-[10%]'>
					<ButtonFab
						design={'drop-shadow-lg shadow-inner text-grey-50'}
						label={t('Continuar')}
						action={() => navigate(-1)}
					/>
				</div>
			) : (
				<div className='my-[20%] flex w-full flex-row justify-center gap-3 md:my-[10%]'>
					<ButtonFab
						design={'drop-shadow-lg shadow-inner text-grey-50'}
						label='Cancelar'
						colour='white'
						action={() => navigate(-1)}
						icon={Cross}
					/>
					{filesToUpload.length > 0 && (
						<ButtonFab
							design={'drop-shadow-lg'}
							colour='green'
							disabled={loading || postulationStatusDocument === 'loading'}
							label={
								loading || postulationStatusDocument === 'loading'
									? 'Guardando'
									: 'Guardar'
							}
							type='submit'
							action={onSubmitAction}
							icon={
								loading || postulationStatusDocument === 'loading'
									? SpinnerWhite
									: CheckIcon
							}
						/>
					)}
				</div>
			)}
			{showDeleteModal && (
				<ViewModal
					setConfirmDeleteElement={() =>
						handleDeleteDocument(
							idOfPostulationFileToDelete,
							idOfFormFileToDelete
						)
					}
					closeMenu={setShowDeleteModal}
					modalTitle={'Eliminar Documento'}
					modalTextOne={
						'¿Seguro que deseas eliminar permanentemente el documento?'
					}
				/>
			)}
			{showDropZoneModal && (
				<div>
					<DropZoneModalDocument
						showDropZoneModal={showDropZoneModal}
						setShowDropZoneModal={setShowDropZoneModal}
						dropZoneFiles={dropZoneFiles}
						clearDropzone={clearDropzone}
						setDropZoneFiles={setDropZoneFiles}
						maxFiles={1}
						updateFiles={updateFiles}
						handleDelete={handleDeleteDropZone}
					/>
				</div>
			)}
		</TemplateWithNavBar>
	);
};

export default PostulationCVForm;
