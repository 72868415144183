import { NAVBAR_ES } from './NavbarTranslations/Navbar_es';
import { ONBOARDING_ES } from './OnboardingTranslations/Onboarding_es';
import { SINGLE_ES } from './SingleTranslations/Single_es';
import { VIEWALL_ES } from './ViewAllTraslations/ViewAll_es';
import { FORM_ES } from './FormTranslations/Form_es';
import { PAYMENTS_ES } from './PaymentsTranslations/Payments_es';
import { MEMBERSHIP_ES } from './MembershipTranslations/Membership_es';

export const TRANSLATIONS_ES = {
	...NAVBAR_ES,
	...SINGLE_ES,
	...FORM_ES,
	...ONBOARDING_ES,
	...VIEWALL_ES,
	...PAYMENTS_ES,
	...MEMBERSHIP_ES,
};
