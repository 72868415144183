import React, { useEffect } from 'react';
import TemplateWithNavBar from '../../templates/TemplateWithNavBar';
import NavbarSecondaryNew from '../Navbar/NavbarSecondaryNew';
import CoursesGrid from './CoursesGrid';
import { useTranslation } from 'react-i18next';

const Courses = () => {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<>
			<TemplateWithNavBar>
				<NavbarSecondaryNew
					title={t('cursosVirtuales')}
					color='bg-yellow-50'
					textColor='text-yellow-50'
					showDots={false}
					textBackDesign='pl-3 text-yellow-50'
					textBack={t('volverAMembresia')}
					redirect='/membresia'
					arrowLeftColor='yellow'
				/>
				<CoursesGrid className='mb-10 mt-5' />
			</TemplateWithNavBar>
		</>
	);
};

export default Courses;
