import { ReactComponent as ArtWorkWhite } from '../../UI/assets/icons/ArtWorkWhite.svg';
import { ReactComponent as DocumentsWhite } from '../../UI/assets/icons/DocumentsWhite.svg';
import { ReactComponent as PlacesWhite } from '../../UI/assets/icons/PlacesWhite.svg';
import { ReactComponent as ContactsWhite } from '../../UI/assets/icons/ContactsWhite.svg';
import { ReactComponent as CollectionsWhite } from '../../UI/assets/icons/CollectionsWhite.svg';
import { ReactComponent as PortfolioWhite } from '../../UI/assets/icons/PortfolioWhite.svg';
import { ReactComponent as CallWhite } from '../../UI/assets/icons/CallWhite.svg';
import { ReactComponent as PostulationWhite } from '../../UI/assets/icons/PostulationWhite.svg';
import { ReactComponent as ExhibitionsWhite } from '../../UI/assets/icons/ExhibitionsWhite.svg';

const navbarOptionsOrganization = (token) => {
	return [
		{
			name: 'Convocatorias',
			subItems: [
				{
					name: 'ConvocatoriasAbiertas',
					icon: <CallWhite />,
					url: token ? '/convocatorias' : '/iniciar-sesion',
				},
			],
		},
	];
};

const navbarOptionsArtist = (appUser) => {
	const userRole = appUser.role_company;
	const activePlan = appUser.subscription_type;
	const isArtistUser = userRole === 'ARTIST';
	const isActivePlanFree = activePlan === 'PlanGratis';

	const options = [
		{ name: 'Perfil', url: '/' },
		{
			name: 'Catalogación',
			subItems: [
				{ name: 'Obras', icon: <ArtWorkWhite />, url: '/obras' },
				{ name: 'Documentos', icon: <DocumentsWhite />, url: '/documentos' },
			],
		},
		{
			name: 'Difusión',
			subItems: [
				{
					name: 'Colecciones',
					icon: <CollectionsWhite />,
					url: '/colecciones',
				},
				{ name: 'Portafolios', icon: <PortfolioWhite />, url: '/portafolios' },
			],
		},
		{
			name: 'Convocatorias',
			subItems: [
				{
					name: 'ConvocatoriasAbiertas',
					icon: <CallWhite />,
					url: '/convocatorias',
				},
				{
					name: 'MisPostulaciones',
					icon: <PostulationWhite />,
					url: '/postulaciones',
				},
			],
		},
	];

	if (isArtistUser && !isActivePlanFree) {
		options.push({
			name: 'Membresía',
			url: '/membresia',
		});
	}

	return options;
};

const navbarOptionsCatalogueArtist = [
	{ name: 'Perfil', url: '/' },
	{
		name: 'Catalogación',
		subItems: [
			{ name: 'Obras', icon: <ArtWorkWhite />, url: '/obras' },
			{ name: 'Documentos', icon: <DocumentsWhite />, url: '/documentos' },
			{ name: 'Lugares', icon: <PlacesWhite />, url: '/lugares' },
			{ name: 'Contactos', icon: <ContactsWhite />, url: '/contactos' },
			{
				name: 'Exposiciones',
				icon: <ExhibitionsWhite />,
				url: '/exposiciones',
			},
		],
	},
	{
		name: 'Difusión',
		subItems: [
			{ name: 'Colecciones', icon: <CollectionsWhite />, url: '/colecciones' },
			{ name: 'Portafolios', icon: <PortfolioWhite />, url: '/portafolios' },
		],
	},
	{
		name: 'Convocatorias',
		subItems: [
			{
				name: 'ConvocatoriasAbiertas',
				icon: <CallWhite />,
				url: '/convocatorias',
			},
			{
				name: 'MisPostulaciones',
				icon: <PostulationWhite />,
				url: '/postulaciones',
			},
		],
	},
];

const navbarOptionsGallery = [
	{ name: 'Artistas', url: '/artistas' },
	{
		name: 'Catalogación',
		subItems: [{ name: 'Obras', icon: <ArtWorkWhite />, url: '/obras' }],
	},
	{
		name: 'Difusión',
		subItems: [
			{ name: 'Colecciones', icon: <CollectionsWhite />, url: '/colecciones' },
			{ name: 'Portafolios', icon: <PortfolioWhite />, url: '/portafolios' },
		],
	},
	{
		name: 'Convocatorias',
		subItems: [
			{
				name: 'ConvocatoriasAbiertas',
				icon: <CallWhite />,
				url: '/convocatorias',
			},
			{
				name: 'MisPostulaciones',
				icon: <PostulationWhite />,
				url: '/postulaciones',
			},
		],
	},
];

export {
	navbarOptionsGallery,
	navbarOptionsArtist,
	navbarOptionsCatalogueArtist,
	navbarOptionsOrganization,
};
