export const MEMBERSHIP_EN = {
	membresia: 'Membership',
	marcaTuCalendario: 'Save the date: New virtual course!',
	dictadoPor: 'Taught by',
	fecha: 'Date',
	hora: 'Time',
	horaBogota: '(Bogota Time)',
	cursosVirtuales: 'Virtual Courses',
	cursosVirtualesDescripcion:
		'Welcome to the exclusive course collection for Datarte members. Here you will find a series of monthly sessions designed to provide you with specialized knowledge and practical tools that will enhance your professional development.',
	mentoriaVirtual: 'Virtual Mentoring',
	mentoriaVirtualDescripcion:
		'In our weekly mentoring sessions, we offer expert support to resolve any questions about your application, profile, or artistic development. Get the guidance you need to move forward with confidence and ensure success in your projects. We look forward to seeing you!',
	registrateAqui: 'Register here',
	materialDeApoyo: 'Support Material',
	materialDeApoyoDescripcion:
		'Complement what you have learned with these readings, worksheets, and activities chosen for you. You can access all the material through this link',
	linkMaterialDeApoyo: 'Link / Support Material',
	verMas: 'See more',
	noCursosDisponibles: 'No courses available',
	noCursosDisponiblesDescripcion:
		'Check back soon to see new available courses.',
	curso: 'Course',
	cursoVirtual: 'Virtual Course',
	cursoNuevo: 'New course',
	con: 'with',
	volverAMembresia: 'Back to membership',
};
