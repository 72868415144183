export const portfolioTypeOptions = [
	{
		title: 'Catálogo prediseñado en Datarte',
		subtitle: 'Datarte creó un formato para esto.',
		description:
			'Los artistas deberán escoger sus obras y Datarte creará una plantilla con estas obras.',
		value: 'CATALOGUE',
		preview: '',
	},
	{
		title: 'Portafolio prediseñado en Datarte',
		subtitle: 'Portafolio creado en Datarte.',
		description:
			'El artista escogerá el portafolio que desee creado en Datarte.',
		value: 'FREE',
		preview: '/plantilla/portafolio_basico',
	},
];

export const CALL_STATUS = (roleCompany) => {
	const isOrganizationUser = roleCompany === 'ORGANIZATION';
	const isGalleryUser = roleCompany === 'GALLERY';
	const isArtistUser = roleCompany === 'ARTIST';

	return {
		OPEN: {
			text: 'Activa',
			textColor: 'text-green-500',
		},
		CLOSED: {
			text: 'Finalizada',
			textColor: 'text-red-500',
		},
		SUBMITTED: {
			text: isArtistUser ? 'Enviada' : 'Nueva',
			textColor: 'text-blue-500',
		},
		DRAFT: {
			text: 'Borrador',
			textColor: 'text-gray-500',
		},
		APPROVED: {
			text: 'Aprobada',
			textColor: 'text-green-500',
		},
		REJECTED: {
			text: 'Rechazada',
			textColor: 'text-red-500',
		},
		REVIEWED: {
			text: 'Revisada',
			textColor: 'text-light_green-50',
		},
		READ: {
			text: isGalleryUser || isOrganizationUser ? 'Leída' : 'Enviada',
			textColor: 'text-blue-500',
		},
		SAVED: {
			text: isGalleryUser ? 'Guardada' : 'Enviada',
			textColor: isGalleryUser ? 'text-purple-500' : 'text-blue-500',
		},
	};
};
