// 3rd parties
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

// BL

import {
	cleanFormTemporaryCallSlug,
	updateMainImageUploadedBeforeSubmit,
	updateOrganizerImageUploadedBeforeSubmit,
} from '../../../BusinessLogic/redux/reducers/call';

import { singleCallFormValidationSchema } from '../../../BusinessLogic/data/singleCallData';
import {
	useGetSingleCall,
	usePutCall,
} from '../../../BusinessLogic/hooks/query/useQueryCalls';

// UI
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import SingleCallFormFormik from './SingleCallFormFormik';

/**
 * @description render the form for the creation of a call from organizations.
 * @returns
 */

const SingleCallForm = ({ isEditing }) => {
	const { t } = useTranslation();
	const { slug } = useParams();

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const putCallMutation = usePutCall();

	const { organization_user_id } = localStorage;
	const { editingCall, formTemporaryCallSlug } = useSelector(
		(state) => state.call
	);

	const { singleCall } = useGetSingleCall(slug);

	const [isLoading, setIsLoading] = useState(false);
	const [isSlugAlreadyUsed, setIsSlugAlreadyUsed] = useState(false);

	const [showDocumentsError, setShowDocumentsError] = useState(false);
	const [showOrganizationNameError, setShowOrganizationNameError] =
		useState(false);

	const handleOnSubmit = async (values) => {
		if (
			String(organization_user_id) !== String(singleCall?.organization_user_id)
		)
			return;

		const mainFile = editingCall.main_image_uploaded_before_submit[0]?.file;
		const organizerFile =
			editingCall.organizer_image_uploaded_before_submit[0]?.file;
		let valuesToSend = {
			...values,
		};
		valuesToSend.route_slug = formTemporaryCallSlug ?? slug;
		valuesToSend.status = 'OPEN';
		if (mainFile) {
			valuesToSend['large_file'] = mainFile;
		}
		if (organizerFile) {
			valuesToSend['call_organizer_file'] = organizerFile;
		}

		if (showDocumentsError || showOrganizationNameError) return;

		setIsLoading(true);
		const res = await putCallMutation
			.mutateAsync(valuesToSend)
			.finally(() => setIsLoading(false));

		if (res.code === 3) {
			setIsSlugAlreadyUsed(true);
		} else {
			dispatch(cleanFormTemporaryCallSlug());
			dispatch(updateMainImageUploadedBeforeSubmit([]));
			dispatch(updateOrganizerImageUploadedBeforeSubmit([]));
			navigate(`/convocatorias`);
		}
	};

	const backAction = () => {
		dispatch(updateMainImageUploadedBeforeSubmit([]));
		dispatch(updateOrganizerImageUploadedBeforeSubmit([]));
	};

	return (
		<TemplateWithNavBar>
			<NavbarSecondaryNew
				title={singleCall?.name ? 'Editar convocatoria' : 'Nueva convocatoria'}
				textColor='text-purple-50 w-max'
				color='bg-purple-50'
				showDots={false}
				redirect={`/convocatorias`}
				textBack={`${t('RegresarConvocatoria')}`}
				textBackDesign='pl-3 text-purple-50'
				arrowLeftColor='purple'
				backAction={backAction}
			/>
			<Formik
				enableReinitialize
				initialValues={{
					...editingCall,
					route_slug:
						formTemporaryCallSlug ?? singleCall?.route_slug.toLowerCase(),
				}}
				validationSchema={singleCallFormValidationSchema}
				onSubmit={(values) => {
					handleOnSubmit(values);
				}}
			>
				{(formProps) => (
					<SingleCallFormFormik
						singleCall={singleCall}
						editingCall={editingCall}
						isLoading={isLoading}
						putCallMutation={putCallMutation}
						isSlugAlreadyUsed={isSlugAlreadyUsed}
						formProps={formProps}
						isEditing={isEditing}
						showDocumentsError={showDocumentsError}
						showOrganizationNameError={showOrganizationNameError}
						setShowDocumentsError={setShowDocumentsError}
						setShowOrganizationNameError={setShowOrganizationNameError}
					/>
				)}
			</Formik>
		</TemplateWithNavBar>
	);
};

export default SingleCallForm;
