import { api } from '../helpers/axios';
import { submitErrorToSlack } from '../helpers/SlackIntegrations';

export const fetchGetData = async (url) => {
	const { token } = localStorage;

	return api
		.get(url, {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		})
		.then((res) => {
			if (res.status === 200 || res.status === 201) {
				return res.data;
			}
			throw new Error(res);
		})
		.catch((error) => {
			if (error.response.status >= 500) {
				submitErrorToSlack(url, error, 'GET');
			}
			console.error(error);
			throw error;
		});
};
