// 3rd Party
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

// BL
import {
	getArtist,
	putArtist,
	organizeImageGrid,
	getArtists,
} from '../../../BusinessLogic/redux/reducers/artist';
import { getDocuments } from '../../../BusinessLogic/redux/reducers/document';
import {
	buttonsArtist,
	buttonsGalleryArtists,
	artistValidationSchema,
} from '../../../BusinessLogic/data/ArtistSingleData';
import { ENDPOINT_FILES } from '../../../BusinessLogic/helpers/routes';
import { ENDPOINTS_DOCUMENT } from '../../../BusinessLogic/helpers/routes';
import { deleteFile } from '../../../BusinessLogic/redux/reducers/deleteElement';
import { createImage } from '../../../BusinessLogic/redux/reducers/createImage';
import { createMainImage } from '../../../BusinessLogic/redux/reducers/createMainImage';
import { compressAndResizeImage } from '../../../BusinessLogic/helpers/imageSize';
import {
	backSectionURL,
	saveSectionURL,
} from '../../../BusinessLogic/redux/reducers/navigation';
import textGoBackGenerator from '../../../BusinessLogic/helpers/textGoBackGenerator';

// UI
import NewTagButtons from '../../../UI/molecules/Buttons/Tag/NewTagButtons';
import NavbarSecondaryNew from '../../../UI/organisms/Navbar/NavbarSecondaryNew';
import TemplateWithNavBar from '../../../UI/templates/TemplateWithNavBar';
import ImageCarousel from '../../../UI/molecules/Image/ImageCarousel';
import TabImageAndEdit from '../../../UI/organisms/Tabs/TabImageAndEdit';
import TabInfoAndEdit from '../../../UI/organisms/Tabs/TabInfoAndEdit';
import TabDocumentAndEdit from '../../../UI/organisms/Tabs/TabDocumentAndEdit';
import { ReactComponent as Delete } from '../../../UI/assets/icons/Delete.svg';
import ButtonFabExtendeNav from '../../../UI/molecules/Buttons/FABExtended/ButtonFabExtendeNav';
import ViewSingleSkeleton from '../../../UI/templates/SkeletonTemplates/ViewSingleSkeleton';
import PageNotFound from '../../fallBackPages/PageNotFound';
import { ModalPostulation } from '../../../UI/organisms/PopUp/ModalPostulation';

/**
 * @description renders the single artist section. It has an Image carousel and artistSingleTabs.
 * @param {*} param0
 * @returns
 */

const ArtistSingle = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const location = useLocation();

	const { t } = useTranslation();
	const { singleArtist, artistSingleTabs, artists, status } = useSelector(
		(state) => state.artists
	);
	const { elementInfo } = useSelector((state) => state.deleteElement);
	const { navigationHistoryURL } = useSelector((state) => state.navigation);
	const { appUser } = useSelector((state) => state.appUser);
	const navigate = useNavigate();

	const isGalleryAccount = (appUser.role_company ?? '') === 'GALLERY';
	const isArtistAccount = (appUser.role_company ?? '') === 'ARTIST';

	const isCatalogueArtistUser =
		(appUser.role_company ?? '') === 'ARTIST_CATALOGATION';

	const [indexInfo, setIndexInfo] = useState(0);
	const [showModalCannotDelete, setShowModalCannotDelete] = useState(false);

	const handleTabsContentToRender = () => {
		if (isArtistAccount || isCatalogueArtistUser) {
			return artistSingleTabs;
		}
		if (isGalleryAccount) {
			let galleryArtistSingleTabs = JSON.parse(
				JSON.stringify(artistSingleTabs)
			);
			galleryArtistSingleTabs = galleryArtistSingleTabs.filter(
				(tab) => tab.type !== 'Documents'
			);

			return galleryArtistSingleTabs;
		}
	};

	const tabsContentToRender = handleTabsContentToRender();

	const imagesForCarousel = tabsContentToRender?.find(
		(tab) => tab.type === 'Images'
	)?.content[0]?.information;

	useEffect(() => {
		dispatch(getArtists());
		dispatch(getArtist({ id })).then((data) => {
			if (data?.error?.message.includes('404')) {
				navigate('/not-found');
			}
		});
		//eslint-disable-next-line
	}, []);

	const onSubmitAction = (values) => {
		dispatch(putArtist(values)).then(() => {
			dispatch(getArtist({ id }));
			dispatch(getArtists());
		});
	};

	const createImagesFunction = async (imagesArray) => {
		for await (const image of imagesArray) {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'ARTIST',
				type_id: id,
				organization_user_id: singleArtist.organization_user_id,
				file_type: 'IMAGE',
			};
			await dispatch(createImage(values));
		}
		dispatch(getArtist({ id }));
	};

	const createMainImageFunction = (mainImageArray) => {
		mainImageArray.forEach(async (image) => {
			let imageObject = await compressAndResizeImage(image.file);
			let { small_file, medium_file, large_file } = imageObject;
			let values = {
				small_file,
				medium_file,
				large_file,
				name_image: image.file.name.split('.').shift(),
				description: image.file.name.split('.').shift(),
				type: 'ARTIST',
				type_id: id,
				organization_user_id: singleArtist.organization_user_id,
				file_type: 'IMAGE',
			};
			dispatch(createMainImage(values)).finally(() => {
				dispatch(getArtist({ id }));
				dispatch(getArtists());
			});
		});
	};

	const deleteDocumentFunction = (documentId) => {
		let values = {
			element_id: ArtistSingle.id,
			element: 'DOCUMENT',
			url: `${ENDPOINTS_DOCUMENT.DELETE_DOCUMENT}${documentId}/`,
		};
		dispatch(deleteFile(values)).then(() => {
			dispatch(getArtist({ id }));
			dispatch(getDocuments());
		});
	};

	const deleteImagesFunction = (imageId) => {
		let values = {
			element_id: singleArtist.id,
			element: 'ARTIST',
			url: `${ENDPOINT_FILES.DELETE_FILE}${imageId}/`,
		};
		dispatch(deleteFile(values)).then(() => dispatch(getArtist({ id })));
	};

	const submitOrganizeImageGrid = (sortableImages) => {
		let values = {
			images: sortableImages,
			type: 'ARTIST',
			type_id: id,
		};
		dispatch(organizeImageGrid(values)).then(() => {
			dispatch(getArtist({ id }));
			dispatch(getArtists());
		});
	};

	const checkRedirect = () => {
		let redirect = appUser.role_company === 'GALLERY' ? '/artistas' : '/';
		if (navigationHistoryURL.length === 0) return redirect;
		else {
			redirect = navigationHistoryURL[navigationHistoryURL.length - 1];
			return redirect;
		}
	};

	const returnBackAction = () => {
		if (navigationHistoryURL.length > 0) dispatch(backSectionURL());
	};

	const CheckTextBack = () => {
		const defaultMessage =
			appUser.role_company === 'GALLERY' ? 'RegresarArtistas' : 'RegresarHome';
		const resultMessage = textGoBackGenerator(
			navigationHistoryURL,
			defaultMessage
		);
		return t(resultMessage);
	};

	const renderTabSTitles = () => {
		if (isGalleryAccount) return buttonsGalleryArtists;
		if (isArtistAccount || isCatalogueArtistUser) return buttonsArtist;
		return [];
	};

	const saveURLHistory = () => {
		dispatch(saveSectionURL(location.pathname));
	};
	const renderSwitch = () => {
		switch (tabsContentToRender[indexInfo]?.type) {
			case 'Information':
				return (
					<TabInfoAndEdit
						information={tabsContentToRender[indexInfo]}
						onSubmitAction={onSubmitAction}
						indexInfo={indexInfo}
						colorHr='purple'
						id={parseInt(id)}
						validationSchema={
							artistValidationSchema[indexInfo]
								? artistValidationSchema[indexInfo]
								: null
						}
					/>
				);
			case 'Artworks':
				return (
					<TabImageAndEdit
						information={tabsContentToRender[indexInfo]}
						indexInfo={indexInfo}
						colorHr='purple'
						AddIconLink={`/${tabsContentToRender[
							indexInfo
						].tab.toLowerCase()}/new`}
						saveLastSectionURL={true}
						saveURLHistory={saveURLHistory}
						objectFitProp='contain'
					/>
				);
			case 'Images':
				return (
					<TabImageAndEdit
						information={tabsContentToRender[indexInfo]}
						indexInfo={indexInfo}
						colorHr='purple'
						AddIconLink={`/${tabsContentToRender[
							indexInfo
						].tab.toLowerCase()}/new`}
						deleteImagesFunction={deleteImagesFunction}
						createImagesFunction={createImagesFunction}
						submitOrganizeImageGrid={submitOrganizeImageGrid}
						saveLastSectionURL={
							tabsContentToRender[indexInfo].tab !== 'Imágenes' ? true : false
						}
						saveURLHistory={saveURLHistory}
					/>
				);
			case 'Documents':
				return (
					<TabDocumentAndEdit
						information={tabsContentToRender[indexInfo]}
						indexInfo={indexInfo}
						color='purple'
						saveLastSectionURL={true}
						AddIconLink={`/documentos/new`}
						saveURLHistory={saveURLHistory}
						deleteDocumentFunction={deleteDocumentFunction}
					/>
				);
			default:
				return <h1>No hay artista disponible</h1>;
		}
	};

	if (status === 'loading' || !singleArtist) {
		return <ViewSingleSkeleton numberOfSingleRow={6} designCaption={'h-22'} />;
	}

	if (
		parseInt(singleArtist?.code) === 403 ||
		parseInt(singleArtist?.code) === 404
	) {
		return <PageNotFound />;
	}

	return (
		<TemplateWithNavBar section='Perfil'>
			{showModalCannotDelete && (
				<ModalPostulation
					modalTitle={t('ArtistaNoPuedeEliminarse')}
					modalText={t('ArtistaNoPuedeEliminarseDescription')}
					buttonLabel={t('Cerrar')}
					modalClassName={'mx-auto w-4/5 px-8'}
					actionClose={() => setShowModalCannotDelete(false)}
				/>
			)}
			<NavbarSecondaryNew
				title={singleArtist?.name}
				color='bg-purple-50'
				textColor='text-purple-50 w-full'
				redirect={checkRedirect()}
				textPopup={`${t('EliminarPerfil')}`}
				popupIcon={<Delete />}
				modalTitle={`${t('EliminarPerfil')}`}
				modalTextOne={`${t('SeguroQueDeseasEliminarEstePerfil')}`}
				modalTextTwo={elementInfo}
				id={parseInt(id)}
				textBack={CheckTextBack()}
				textBackDesign='pl-3 text-purple-50'
				backAction={() => returnBackAction()}
				section='artistas'
				showDots={isGalleryAccount}
				setShowModalCannotDelete={setShowModalCannotDelete}
			/>
			{appUser.role_company !== 'ARTIST' && (
				<ButtonFabExtendeNav
					id={parseInt(id)}
					listItems={artists?.length ? artists : []}
					section='artistas'
				/>
			)}

			<div className='flex flex-col md:flex-row md:gap-2 lg:gap-0'>
				<ImageCarousel
					images={imagesForCarousel}
					mainPicture={singleArtist.main_picture_url}
					createMainImageFunction={createMainImageFunction}
					sectionTitle={'Images'}
				/>
				<div className='flex w-full flex-col px-2 md:w-2/3 xl:w-2/3'>
					<NewTagButtons
						buttonList={renderTabSTitles()}
						indexInfo={indexInfo}
						setIndexInfo={setIndexInfo}
					/>

					<div className='min-h-screen bg-light_grey-50 py-4'>
						{renderSwitch()}
					</div>
				</div>
			</div>
		</TemplateWithNavBar>
	);
};

export default ArtistSingle;

ArtistSingle.propTypes = {};
