// 3rd party
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
//UI
import { ReactComponent as Close } from '../../assets/icons/CrossCircle.svg';
import ItemSectionNavbarDesktop from '../../molecules/NavBarItems/ItemSectionNavbarDesktop';
import SubMenu from './SubMenu';
import SelectorLanguage from '../../molecules/Selector/SelectorLanguaje';
import MainLogoDesktop from '../../molecules/MainLogoDesktop';
import logoDatarte from '../../assets/icons/logoDatarte.svg';
import { ReactComponent as Location } from '../../assets/icons/Location.svg';
import { resetHistoryURL } from '../../../BusinessLogic/redux/reducers/navigation';

/**
 * @description Menu Modal that show in mobile device
 * @param {*} param0
 * @returns
 */
export default function Menu({
	section,
	options,
	setShowMenu,
	normalState,
	rotateState,
}) {
	const dispatch = useDispatch();
	const [subOptions, setSubOptions] = useState(false);
	const [subMenuItems, setSubMenuItems] = useState(['hello']);
	const [indexItem, setIndexItem] = useState('');
	const [labelItem, setLabelItem] = useState('');
	const { t } = useTranslation();
	const handleCloseMenu = () => {
		setSubOptions(false);
		dispatch(resetHistoryURL());
	};
	useEffect(() => {
		document.body.classList.add('overflow-hidden');
		return () => {
			document.body.classList.remove('overflow-hidden');
		};
	}, []);

	return (
		<div className='fixed left-0 top-0 z-20 flex h-full w-full flex-col justify-between bg-green-50 p-4 sm:p-12'>
			<div className='flex flex-row items-center justify-between py-5'>
				<Close onClick={() => setShowMenu(false)} className=' cursor-pointer' />
				<div className='flex flex-row items-center'>
					<Location className='mr-2' />
					<SelectorLanguage
						languageList={['ES', 'EN']}
						popupDesign='bg-white py-1 bg-items-center rounded-lg w-22 right-0 ml-5'
						letterColor='text-green-50'
					/>
				</div>
			</div>
			<div className='flex w-fit-content flex-col justify-between px-8'>
				<div className='flex w-full flex-col justify-between text-center text-white'>
					{options.map((item, index) => (
						<div className='mb-16 flex flex-col'>
							<ItemSectionNavbarDesktop
								option={t(item.name)}
								url={item.url}
								subItems={item.subItems}
								section={section}
								subOptions={subOptions}
								setSubOptions={setSubOptions}
								setSubMenuItems={setSubMenuItems}
								index={index}
								setIndexItem={setIndexItem}
								indexItem={indexItem}
								normalState={normalState}
								rotateState={rotateState}
								setLabelItem={setLabelItem}
								closeMenu={() => handleCloseMenu()}
							/>
						</div>
					))}
				</div>
			</div>
			<div className='flex  w-full flex-col-reverse justify-between'>
				<MainLogoDesktop
					design='self-center pb-8'
					image={logoDatarte}
					alt='datarte.art'
					action={() => handleCloseMenu()}
				/>
			</div>
			{subOptions && (
				<SubMenu
					subMenuClose={setSubOptions}
					subMenuItems={subMenuItems}
					title={labelItem}
					setShowMenu={setShowMenu}
					handleCloseMenu={handleCloseMenu}
				/>
			)}
		</div>
	);
}

Menu.propTypes = {
	/** array of objects that contents the name of the option and nested options */
	option: PropTypes.string,
	/** Compares with the string to know if has been selected */
	section: PropTypes.string,
	/** normal direction of the arrow */
	normalState: PropTypes.string,
	/** change direction of the arrow */
	rotateState: PropTypes.string,
};

Menu.defaultProps = {};
