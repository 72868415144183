import { ENDPOINTS_COURSES } from '../../helpers/routes';
import { fetchGetData } from '../fetchGetData';
import { useQuery } from '@tanstack/react-query';

const getCourseEvent = async () => {
	const endpoint = ENDPOINTS_COURSES.GET_COURSE_EVENT;

	const response = await fetchGetData(endpoint);
	const dataToReturn = response?.event?.course;

	return dataToReturn;
};

export const useGetCourseEvent = () => {
	const { data, isError, error, isLoading, refetch } = useQuery({
		queryKey: ['courseEvent'],
		retry: false,
		queryFn: () => getCourseEvent(),
	});

	return {
		courseEvent: data,
		isLoading,
		isError,
		error,
		refetch,
	};
};

const getCourses = async () => {
	const endpoint = ENDPOINTS_COURSES.GET_COURSES;

	const response = await fetchGetData(endpoint);
	const dataToReturn = response?.courses;

	return dataToReturn;
};

export const useGetCourses = () => {
	const { data, isError, error, isLoading, refetch } = useQuery({
		queryKey: ['courses'],
		queryFn: () => getCourses(),
		retry: 1,
	});

	return {
		courses: data,
		isLoading,
		isError,
		error,
		refetch,
	};
};
