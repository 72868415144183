import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

/**
 * @description component that renders custom SEO meta tags
 */
const SEO = ({ title, description, ogTitle, ogDescription, ogImage }) => {
	return (
		<Helmet>
			<html translate='no' />
			<title>{title}</title>
			<meta name='google' content='notranslate' />
			<meta name='translate' content='no' />
			<meta name='description' content={description} />
			<meta property='og:title' content={ogTitle} />
			<meta name='og:description' content={ogDescription} />
			<meta property='og:image' content={ogImage} />
		</Helmet>
	);
};

SEO.propTypes = {
	/**Title tag */
	title: PropTypes.string,
	/**Description tag*/
	description: PropTypes.string,
	/** Open Graph protocol title */
	ogTitle: PropTypes.string,
	/** Open Graph protocol description */
	ogDescription: PropTypes.string,
	/** Open Graph protocol image */
	ogImage: PropTypes.string,
};

SEO.defaultProps = {
	title: 'Datarte',
	description:
		'En Datarte ayudamos y acompañamos a los artistas visuales a extender su reconocimiento a nivel nacional e internacional, presentándoles convocatorias y otras estrategias de promoción. También ayudamos a las organizaciones a gestionar sus convocatorias y les presentamos nuevos talentos para descubrir.',
	ogTitle:
		'Datarte |  Descubre y postula a nuevas convocatorias constantemente para que puedas potenciar tu trabajo',
	ogDescription:
		'En Datarte ayudamos y acompañamos a los artistas visuales a extender su reconocimiento a nivel nacional e internacional, presentándoles convocatorias y otras estrategias de promoción. También ayudamos a las organizaciones a gestionar sus convocatorias y les presentamos nuevos talentos para descubrir.',
};

export default SEO;
