//3rd
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

//BL
import {
	deleteExpertPortfolioSlide,
	getExpertPortfolioSlides,
	getPortfolio,
	postExpertPortfolioSlide,
	putExpertPortfolioSlidesOrder,
	setCurrentViewExpertPortfolioAndSelection,
} from '../../../../BusinessLogic/redux/reducers/portfolio';

//UI
import ExpertPortfolioEditionLayout from '../../../../UI/layouts/portfolio/ExpertPortfolioEditionLayout';
import PortfolioDraggableSlides from '../../../../UI/molecules/Portfolio/PortfolioDraggableSlides';
import PortfolioSlidePreview from '../../../../UI/molecules/Portfolio/PortfolioSlidePreview';
import ViewModal from '../../../../UI/templates/ViewModal';
import { ModalPostulation } from '../../../../UI/organisms/PopUp/ModalPostulation';

/**
 * @description Renders the sections view of an expert portfolio
 */
const ExpertPortfolioSlides = () => {
	const { portfolioId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();

	const { singlePortfolio } = useSelector((state) => state.portfolios);

	const slides = singlePortfolio.slides;

	const [selectedSlide, setSelectedSlide] = useState(null);
	const [showReorderView, setShowReorderView] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);

	useEffect(() => {
		dispatch(getPortfolio(portfolioId));
	}, [dispatch, portfolioId]);

	useEffect(() => {
		dispatch(getExpertPortfolioSlides(portfolioId));
	}, [dispatch, portfolioId]);

	useEffect(() => {
		dispatch(
			setCurrentViewExpertPortfolioAndSelection({
				currentView: 'sectionsView',
				selectedElementId: null,
				selectedItemId: null,
				selectedSlideId: null,
			})
		);
	}, [dispatch]);

	useEffect(() => {
		setSelectedSlide(null);
	}, [showReorderView]);

	const handleSelectSlide = (slide) => {
		slide !== selectedSlide ? setSelectedSlide(slide) : setSelectedSlide(null);
	};

	const handleDuplicateSlide = () => {
		dispatch(
			postExpertPortfolioSlide({
				portfolioId,
				portfolioType: 'EXPERT',
				slideType: selectedSlide.type,
				slideId: selectedSlide.slideId,
			})
		);
	};

	const handleDeleteSlide = () => {
		if (slides?.length > 1) {
			dispatch(deleteExpertPortfolioSlide(selectedSlide.slideId));
		} else {
			setShowCannotDeleteModal(true);
		}
	};

	const handleReorderSlides = (reorderedSlidesArray) => {
		const newOrderedSlidesValues = {
			portfolioId,
			portfolioType: 'EXPERT',
			array_slide: reorderedSlidesArray,
		};
		dispatch(
			putExpertPortfolioSlidesOrder({
				portfolioId,
				newOrderedSlidesValues,
			})
		).finally(() => {
			setShowReorderView(false);
		});
	};

	return (
		<>
			{showDeleteModal && (
				<ViewModal
					closeMenu={setShowDeleteModal}
					confirmButtonText={t('Eliminar')}
					modalTextOne={t('SeguroDeseasEliminarEstaSección')}
					modalTitle={t('EliminarSección')}
					setConfirmDeleteElement={handleDeleteSlide}
				/>
			)}
			{showCannotDeleteModal && (
				<ModalPostulation
					modalTitle={t('ExpertPorfolioSlideNoPuedeEliminarse')}
					modalText={t('ExpertPorfolioSlideNoPuedeEliminarseDescripción')}
					buttonLabel={t('Cerrar')}
					modalClassName={'mx-auto w-4/5 px-8'}
					actionClose={() => setShowCannotDeleteModal(false)}
				/>
			)}
			<ExpertPortfolioEditionLayout
				showReorderView={showReorderView}
				setShowReorderView={setShowReorderView}
				duplicateSlideAction={() => {
					setShowReorderView(false);
					selectedSlide && handleDuplicateSlide();
				}}
				deleteSlideAction={() => {
					setShowReorderView(false);
					selectedSlide && setShowDeleteModal(true);
				}}
			>
				{showReorderView ? (
					<PortfolioDraggableSlides
						slides={slides}
						reorderSlidesAction={handleReorderSlides}
						setShowReorderView={setShowReorderView}
					/>
				) : (
					<div className='mx-auto mb-20 grid w-5/6 max-w-6xl grid-cols-1 gap-5 pt-5 sm:grid-cols-2 lg:grid-cols-3'>
						{slides?.map((slide, index) => (
							<div
								className='cursor-pointer'
								key={index}
								onClick={() => handleSelectSlide(slide)}
								onDoubleClick={() =>
									navigate(
										`/portafolios/portafolio-experto/${portfolioId}/edicion`
									)
								}
							>
								<PortfolioSlidePreview
									slide={slide}
									slideDesign={
										slide === selectedSlide &&
										'outline outline-offset-2 outline-green-50'
									}
									slideLabel={`${index + 1}. ${t(slide.type)}`}
								/>
							</div>
						))}
					</div>
				)}
			</ExpertPortfolioEditionLayout>
		</>
	);
};

ExpertPortfolioSlides.protoTypes = {};

export default ExpertPortfolioSlides;
