import { useSelector } from 'react-redux';
//3rd party
import PropTypes from 'prop-types';

//UI
import NavbarDesktop from '../molecules/Navbar/NavbarDesktop';
import NavbarMobileTop from '../molecules/Navbar/NavbarMobileTop';

//BL
import {
	navbarOptionsGallery,
	navbarOptionsArtist,
	navbarOptionsCatalogueArtist,
	navbarOptionsOrganization,
} from '../../BusinessLogic/data/NavbarOptions';

/**
 * @description Template for the add a NavBar on top of the components. We can manage adding or removing the menu on mobile.
 * @returns
 */
function TemplateWithNavBar({
	children,
	section,
	design,
	mainDesign = 'xs:container xs:mx-auto  ',
}) {
	const appUser = useSelector((state) => state.appUser.appUser);

	const navbarOptionsArtistToUse = navbarOptionsArtist(appUser);

	const renderSwitchNavbarOptions = () => {
		switch (appUser.role_company) {
			case 'ARTIST':
				navbarOptionsArtistToUse[0].url = `/artistas/${appUser.main_artist_id}`;
				return navbarOptionsArtistToUse;
			case 'GALLERY':
				return navbarOptionsGallery;
			case 'ARTIST_CATALOGATION':
				navbarOptionsCatalogueArtist[0].url = `/artistas/${appUser.main_artist_id}`;
				return navbarOptionsCatalogueArtist;
			case 'ORGANIZATION':
				return navbarOptionsOrganization(appUser.token);
			default:
				return navbarOptionsOrganization(appUser.token);
		}
	};

	return (
		<div className='min-h-screen'>
			<NavbarDesktop
				section={section}
				options={renderSwitchNavbarOptions()}
				normalState='rotate-90'
				rotateState='-rotate-90'
			/>
			<NavbarMobileTop
				section={section}
				options={renderSwitchNavbarOptions()}
				normalState=''
				rotateState='rotate-180'
			/>
			<main
				className={`${mainDesign} min-h-full px-2 md:overflow-x-hidden md:px-4 lg:px-8 2xl:px-10 ${design}`}
			>
				{children}
			</main>
		</div>
	);
}

TemplateWithNavBar.propTypes = {
	/** Children that goes in the wrapper */
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	/** the label of the section where the user is, like artist or documents */
	section: PropTypes.string,
	/** array of objects that contents the name of the option and nested options */
	option: PropTypes.array,
	/** string prop containing the design for the TemplateWithNavBar component */
	design: PropTypes.string,
};

TemplateWithNavBar.defaultProps = {
	design: '',
};

export default TemplateWithNavBar;
