import React, { useMemo } from 'react';
import Course from './Course';
import { useGetCourses } from '../../../BusinessLogic/hooks/query/useQueryCourses';
import PortfolioLoading from '../Loading/PortfolioLoading';
import { useTranslation } from 'react-i18next';
import ButtonLinkSeeMore from '../../atoms/Buttons/Link/ButtonLinkSeeMore';

const COURSES_TO_SHOW = 6;

const CoursesGrid = ({ seeAll = false, className }) => {
	const { t } = useTranslation();
	const { courses, isLoading } = useGetCourses();
	const coursesToShow = useMemo(() => {
		if (!courses) return [];
		if (seeAll) return courses.slice(0, COURSES_TO_SHOW);
		return courses;
	}, [courses, seeAll]);

	if (isLoading) return <PortfolioLoading />;

	if (!courses || courses.length === 0)
		return (
			<div className='flex flex-col items-center justify-center gap-4 py-10'>
				<div className='text-center text-xl font-bold text-gray-500 lg:text-3xl'>
					{t('noCursosDisponibles')}
				</div>
				<p className='text-center text-base text-gray-500 lg:text-xl'>
					{t('noCursosDisponiblesDescripcion')}
				</p>
			</div>
		);

	return (
		<>
			<div
				className={`grid grid-cols-1 gap-4  md:grid-cols-2 md:gap-y-1 lg:grid-cols-3 lg:gap-x-4 lg:gap-y-2  ${className}`}
			>
				{coursesToShow.map((course, index) => (
					<Course key={course.id} {...course} isNew={index === 0} />
				))}
			</div>
			{seeAll && courses.length > COURSES_TO_SHOW && (
				<ButtonLinkSeeMore
					link='/membresia/cursos'
					design='ml-auto border-solid border border-black-50 text-black-50 rounded-md hover:bg-light_purple-50  py-1 mt-4 sm:mt-auto'
				/>
			)}
		</>
	);
};

export default CoursesGrid;
