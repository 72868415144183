export const MEMBERSHIP_ES = {
	membresia: 'Membresía',
	marcaTuCalendario: 'Marca tu calendario: Nuevo curso virtual!',
	dictadoPor: 'Dictado por',
	fecha: 'Fecha',
	hora: 'Hora',
	horaBogota: '(Hora Bogotá)',
	cursosVirtuales: 'Cursos virtuales',
	cursosVirtualesDescripcion:
		'Bienvenid@ a la colección de cursos exclusivos para miembros de Datarte. Aquí encontrarás una serie de sesiones mensuales diseñadas para brindarte conocimientos especializados y herramientas prácticas que potenciarán tu desarrollo profesional.',
	mentoriaVirtual: 'Mentoría virtual',
	mentoriaVirtualDescripcion:
		'En nuestras sesiones de mentorías semanales, te ofrecemos apoyo experto para resolver cualquier duda sobre tu postulación, perfil o desarrollo artístico. Obtén la orientación que necesitas para avanzar con confianza y asegurar el éxito en tus proyectos. ¡Te esperamos!',
	registrateAqui: 'Regístrate aquí',
	materialDeApoyo: 'Material de apoyo',
	materialDeApoyoDescripcion:
		'Complementa lo que has aprendido con estas lecturas, hojas de trabajo y actividades elegidas para ti. Puedes acceder a todo el material a través de este link',
	linkMaterialDeApoyo: 'Link / Material de apoyo',
	verMas: 'Ver más',
	noCursosDisponibles: 'No hay cursos disponibles',
	noCursosDisponiblesDescripcion:
		'Vuelve pronto para ver los nuevos cursos disponibles.',
	curso: 'Curso',
	cursoVirtual: 'Curso virtual',
	cursoNuevo: 'Nuevo curso',
	con: 'con',
	volverAMembresia: 'Volver a membresía',
};
