//3rd
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

//UI
import TextContentSecond from '../Content/TextContentSecond';
import {
	statusToShow,
	textColorStatus,
} from '../../../../BusinessLogic/helpers/callHelpers';
import { CALL_STATUS } from '../../../../BusinessLogic/data/call';

/**
 * @description renders the label and style that represents the status of a call
 * @param {*} param0
 * @returns
 */
const CallStatusLabel = ({ status, design, style, isFromPostulation }) => {
	const { appUser } = useSelector((state) => state.appUser);
	const roleCompany = appUser.role_company;

	const callStatus = CALL_STATUS(roleCompany);
	const callStatusText = callStatus[status].text;
	const callStatusColor = callStatus[status].textColor;

	const callPostulationStatusText = statusToShow(status, roleCompany);
	const callPostulationStatusColor = textColorStatus(status, roleCompany);

	const statusToShowText = isFromPostulation
		? callPostulationStatusText
		: callStatusText;

	const colorStatusText = isFromPostulation
		? callPostulationStatusColor
		: callStatusColor;

	return (
		<TextContentSecond
			text={statusToShowText}
			design={`${colorStatusText} ${design}`}
			style={style}
		/>
	);
};

CallStatusLabel.propTypes = {
	//** Key that represents the status of the call */
	status: PropTypes.string,
	/** Tailwind class*/
	design: PropTypes.string,
	/** React Style object*/
	style: PropTypes.object,
};

CallStatusLabel.defaultProps = {
	style: {
		fontWeight: '700',
	},
};

export default CallStatusLabel;
