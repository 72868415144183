import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { ENDPOINTS_CALL } from '../../helpers/routes';
import { fetchGetData } from '../fetchGetData';
import { fetchPutFormData } from '../fetchPutFormData';
import { fetchPostData } from '../fetchPostData';
import { fetchPutData } from '../fetchPutData';
import { fetchDeleteElement } from '../fetchDeleteElement';
import { useSelector } from 'react-redux';

const getAllCallsByRol = async (appUser) => {
	const { organization_user_id, role_company } = appUser;

	let endpoint = '';

	if (!organization_user_id || !role_company) {
		endpoint = ENDPOINTS_CALL.GET_CALL_ARTIST_ALL;
	} else {
		const isOrganizationUser = role_company === 'ORGANIZATION';

		if (isOrganizationUser) {
			endpoint = `${ENDPOINTS_CALL.GET_CALL_ORGANIZATION_ALL}${organization_user_id}/`;
		} else {
			endpoint = ENDPOINTS_CALL.GET_CALL_ARTIST_ALL;
		}
	}

	const response = await fetchGetData(endpoint);
	const dataToReturn = response?.calls;

	return dataToReturn;
};

export const useGetAllCallsByRole = () => {
	const { appUser } = useSelector((state) => state.appUser);

	const { data, isError, error, isLoading, refetch } = useQuery({
		queryKey: ['calls'],
		queryFn: () => getAllCallsByRol(appUser),
	});

	return {
		calls: data,
		isLoading,
		isError,
		error,
		refetch,
	};
};

//GET CALL SINGLE
// identifier could be call id or call slug
const getCall = async (identifier) => {
	if (!identifier) return;
	const endpoint = `${ENDPOINTS_CALL.GET_CALL}${identifier}/`;
	const response = await fetchGetData(endpoint);
	return response.call;
};

export const useGetSingleCall = (identifier) => {
	const { data, isError, error, isLoading } = useQuery({
		queryKey: ['singleCall', identifier],
		queryFn: () => getCall(identifier),
	});

	return {
		singleCall: data,
		isLoading,
		isError,
		error,
	};
};

//GET SINGLE CALL PUBLIC
// identifier could be call id or call slug
const getCallPublic = async (identifier) => {
	if (!identifier) return;
	const endpoint = `${ENDPOINTS_CALL.GET_CALL_PUBLIC}${identifier}/`;
	const response = await fetchGetData(endpoint);

	return response.call;
};

export const useGetSingleCallPublic = (identifier) => {
	const { data, isError, error, isLoading } = useQuery({
		queryKey: ['singleCallPublic', identifier],
		queryFn: () => getCallPublic(identifier),
	});

	return {
		singleCallPublic: data,
		isLoading,
		isError,
		error,
	};
};

//GET SINGLE CALL FORM

const getCallForm = async (id) => {
	if (!id) return;
	const endpoint = `${ENDPOINTS_CALL.GET_CALL_FORM}${id}`;
	const response = await fetchGetData(endpoint);
	return response;
};

export const useGetCallForm = (id) => {
	const { data, isError, error, isLoading } = useQuery({
		queryKey: ['singleCallForm', id],
		queryFn: () => getCallForm(id),
	});

	return {
		singleCallForm: data,
		isLoading,
		isError,
		error,
	};
};

//PUT CALL

const putCall = async (values) => {
	if (!values) return;
	const endpoint = `${ENDPOINTS_CALL.PUT_CALL}`;
	const response = await fetchPutFormData(endpoint, values);
	return response;
};

export const usePutCall = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (values) => {
			return putCall(values);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['singleCall'],
			});
			queryClient.invalidateQueries({
				queryKey: ['singleCallForm'],
			});
		},
	});
};

// POST CALL

const postCreateCall = async () => {
	const { organization_user_id } = localStorage;

	const endpoint = `${ENDPOINTS_CALL.POST_CALL}`;
	const response = await fetchPostData(endpoint, {
		organization_user_id,
	});
	return response;
};

export const usePostCreateCall = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: () => {
			return postCreateCall();
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['calls'],
			});
		},
	});
};

// POST CREATE CALL FORM

const postCreateCallForm = async () => {
	const endpoint = `${ENDPOINTS_CALL.POST_CALL_FORM}`;
	const response = await fetchPostData(endpoint);
	return response;
};

export const usePostCreateCallForm = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (values) => {
			return postCreateCallForm(values);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['singleCall'],
			});
		},
	});
};

// DELETE_CALL

const deleteCall = async (id) => {
	if (!id) return;
	const endpoint = `${ENDPOINTS_CALL.DELETE_CALL}${id}/`;
	const response = await fetchPutData(endpoint);
	return response;
};

export const useDeleteCall = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (id) => {
			return deleteCall(id);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['calls'],
			});
		},
	});
};

//  DELETE FORM ELEMENT FROM CALL

const deleteFormElementFromCall = async (id) => {
	if (!id) return;
	const endpoint = `${ENDPOINTS_CALL.DELETE_CALL_FORM_ELEMENT}${id}`;
	const response = await fetchDeleteElement(endpoint);
	return response;
};

export const useDeleteFormElementFromCall = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (id) => {
			return deleteFormElementFromCall(id);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['singleCall'],
			});
			queryClient.invalidateQueries({
				queryKey: ['singleCallForm'],
			});
		},
	});
};

//  DELETE BENEFIT ELEMENT FROM CALL

const deleteBenefitElementFromCall = async (id) => {
	if (!id) return;
	const endpoint = `${ENDPOINTS_CALL.DELETE_CALL_BENEFIT_ELEMENT}${id}`;
	const response = await fetchDeleteElement(endpoint);
	return response;
};

export const useDeleteBenefitElementFromCall = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (id) => {
			return deleteBenefitElementFromCall(id);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['singleCall'],
			});
		},
	});
};

//PUT CALL STATUS

const putCallStatus = async (input) => {
	if (!input) return;
	const { id, ...values } = input;
	const endpoint = `${ENDPOINTS_CALL.PUT_CALL_STATUS}${id}/`;
	const response = await fetchPutData(endpoint, values);
	return response;
};

export const usePutCallStatus = () => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (input) => {
			return putCallStatus(input);
		},
		onSuccess: (res) => {
			queryClient.invalidateQueries({
				queryKey: ['singleCall'],
			});
		},
	});
};

// DOWNLOAD EXCEL

const downloadExcel = async (input) => {
	const { id, country_residence, name } = input;
	const endpoint = ENDPOINTS_CALL.DOWNLOAD_EXCEL;

	const values = {
		call_id: id,
		country_residence,
	};

	const response = await fetchPostData(endpoint, values);

	try {
		const blob = new Blob([response], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', `${name}.csv`);
		document.body.appendChild(link);
		link.click();
		link.remove();
		window.URL.revokeObjectURL(url);
	} catch (error) {
		console.log(error);
	}
};

export const useDownloadExcel = () => {
	return useMutation({
		mutationFn: (input) => {
			return downloadExcel(input);
		},
	});
};
