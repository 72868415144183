//3rd party
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';

//UI
import 'react-lazy-load-image-component/src/effects/blur.css';
import TextContentSecond from '../../atoms/Text/Content/TextContentSecond';
import UnderlineHr from '../../atoms/UnderLine/UnderlineHr';
import TextContentPrincipal from '../../atoms/Text/Content/TextContentPrincipal';
import {
	statusToShow,
	textColorStatus,
} from '../../../BusinessLogic/helpers/callHelpers';

/**
 * @description render a card with specific information
 * @param {*} param0
 * @returns
 */
function CardOrganization({
	id,
	name,
	date,
	status,
	redirect,
	textDesign,
	image,
}) {
	const { appUser } = useSelector((state) => state.appUser);
	const roleCompany = appUser.role_company;

	const colorStatusText = textColorStatus(status, roleCompany);
	const statusToShowText = statusToShow(status, roleCompany);

	let redirectPath = redirect ? `/${redirect}/${id}` : '';

	let defaultImage =
		'https://art-catalogue.s3.us-east-2.amazonaws.com/generic/default_image';

	useEffect(() => {
		sessionStorage.setItem('previousPage', 'postulations');
	}, []);

	return (
		<div className='relative mx-auto h-full w-full py-2 '>
			<Link
				className='md:h-30 relative flex w-full items-center gap-4 px-2 pb-2'
				to={{
					pathname: redirectPath,
				}}
			>
				<div className='flex w-20 items-center overflow-hidden rounded-full'>
					<LazyLoadImage
						alt={name}
						src={image ?? defaultImage}
						placeholderSrc={image ?? defaultImage}
						effect='blur'
						wrapperClassName={`max-h-full aspect-square lazyLoadingImages`}
						delayTime='0'
						threshold='800'
						className='object-cover'
					/>
				</div>

				<div className='flex h-full w-full flex-col justify-center py-4'>
					<div className='flex justify-between'>
						<TextContentPrincipal text={name} style={{ fontWeight: 400 }} />
						<TextContentPrincipal
							design={colorStatusText}
							text={statusToShowText}
							style={{ fontWeight: 600 }}
						/>
					</div>
					<TextContentSecond
						design={`break-all ${textDesign}`}
						text={`Aplicado ${date}`}
					/>
				</div>
			</Link>
			<UnderlineHr colour={'purple'} />
		</div>
	);
}

CardOrganization.propTypes = {
	/** Id of the card organization */
	id: PropTypes.number,
	/** complete name of the postulated user */
	name: PropTypes.string,
	/** date of the postulation*/
	date: PropTypes.string,
	/** String for the redirect*/
	redirect: PropTypes.string,
	/* handle the status of the postulation */
	status: PropTypes.string,
	/* function to clean the filters*/
	cleanFilters: PropTypes.func,
	/* styles for text described as applied + date */
	textDesign: PropTypes.string,
	/* image of the application */
	image: PropTypes.string,
};

export default CardOrganization;
