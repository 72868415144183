import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import DefaultImage from '../../assets/images/default-video.jpg';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useIsMobile } from '../../../BusinessLogic/hooks/useMediaQuery';

const Course = ({ title, video_url, thumbnail_url, teacher, isNew }) => {
	const { t } = useTranslation();
	const player = useRef(null);
	const imageUrl = thumbnail_url || DefaultImage;
	const isMobile = useIsMobile();

	return (
		<div className='grid  gap-4 md:grid-rows-[1fr_auto_0.3fr]'>
			<ReactPlayer
				ref={player}
				url={video_url}
				width={'100%'}
				height={isMobile ? '280px' : '330px'}
				controls
				light={
					<img
						className='h-full w-full object-cover'
						src={imageUrl}
						alt='Thumbnail'
					/>
				}
				config={{
					file: {
						attributes: {
							controlsList: 'nodownload noremoteplayback noplaybackrate',
						},
					},
				}}
				className='h-full'
				onContextMenu={(e) => e.preventDefault()}
				playing={document.hasFocus()}
			/>
			{isNew && (
				<div className='ml-auto place-items-end'>
					<div className=' row-end-3  rounded-md bg-yellow-50 px-7 py-1 text-sm text-white'>
						{t('cursoNuevo')}
					</div>
				</div>
			)}

			<h2 className='text-lg font-bold text-green-50 md:row-start-3'>
				{t('curso')}: {title} {t('con')} {teacher.name}
			</h2>
		</div>
	);
};

Course.propTypes = {
	title: PropTypes.string.isRequired,
	video_url: PropTypes.string.isRequired,
	thumbnail_url: PropTypes.oneOfType([PropTypes.string, PropTypes.exact(null)]),
	isNew: PropTypes.bool.isRequired,
	teacher: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}),
};

export default Course;
