import { Routes, Route, Navigate } from 'react-router-dom';

import PageNotFound from '../../Pages/fallBackPages/PageNotFound';
import { useDispatch, useSelector } from 'react-redux';
import Membership from '../../UI/organisms/Membership/Membership';
import Courses from '../../UI/organisms/Courses/Courses';
import { PrivateRoute } from '../../Pages/authentication/components/LoginUtils';
import { useEffect } from 'react';
import { getAppUser } from '../redux/reducers/appUser';

export default function MembershipRouter() {
	const { appUser } = useSelector((state) => state.appUser);
	const dispatch = useDispatch();
	const userRole = appUser.role_company;
	const activePlan = appUser.subscription_type;
	const isArtistUser = userRole === 'ARTIST';
	const isActivePlanFree = activePlan === 'PlanGratis';

	useEffect(() => {
		dispatch(getAppUser());
	}, [dispatch]);

	if (!isArtistUser || isActivePlanFree) {
		return <Navigate to='/' />;
	}

	return (
		<Routes>
			<Route
				path='/'
				element={
					<PrivateRoute>
						<Membership />
					</PrivateRoute>
				}
			/>
			<Route
				path='cursos'
				element={
					<PrivateRoute>
						<Courses />
					</PrivateRoute>
				}
			/>
			<Route path='*' element={<PageNotFound />} />
		</Routes>
	);
}
